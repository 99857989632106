import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faExclamation
} from '@fortawesome/free-solid-svg-icons';
const NoMatch = () => {
    return (
        <div className='inner'>
            <div className='row'>
                <div className='col-12 no_stuff'>
                    <h1 className='largeIcon'><FontAwesomeIcon icon={faExclamation} /></h1>
                            <h1>Page Not Found</h1>
                            <p>This page can't be found please check the URL you entered and try again</p>
                </div>
            </div>
        </div>
    )
}
export default NoMatch;