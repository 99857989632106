import React, {Component, Fragment} from 'react';
import {createContainerBucket} from '../../data/containers';
import {Store} from '../../stores/Store';

class NewContainerBucket extends Component {
    state = {
        form:{
            name:'',
            type:this.props.type
        }
    }
    handleChange = (e) => {
        let form = {...this.state.form}
        form[e.target.name] = e.target.value;
        this.setState({
          form:form
        });
    }
    create = async() =>{
        let error = false;
        error = (this.state.form.name.length > 3 && error === false)?true:(error !== false)?error:'Name needs to be over 3 charactors';
        error = (this.state.form.name.length < 24 && error === false)?true:(error !== false)?error:'Name needs to be less than 24 charactors';
        if(error)
        {
            createContainerBucket(this.context.product_id, this.state.form).then(()=>{
                this.props.update();
                this.props.close();
            });
        }
    }
    render(){
        return (
            <Fragment>
                <div className="input-group mb-3">
                    <div className="input-group-prepend">
                        <span className="input-group-text" id="namelabel">Bucket Name</span>
                    </div>
                    <input 
                        type="text" 
                        name='name' 
                        required={true} 
                        onChange={this.handleChange} 
                        value={this.state.form.name} 
                        className={(this.state.error && this.state.form.name.length < 3)?"form-control formerror":"form-control"} 
                        placeholder={'Bucket Name'} 
                    />
                </div>
                <button className="btn btn-success nomargin floatright space top" onClick={this.create}>Create</button>
            </Fragment>
        )
    }
}
NewContainerBucket.contextType = Store; // This part is important to access context values
export default NewContainerBucket;