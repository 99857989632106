import React, {Component, Fragment} from 'react';
import {updateContainerBucket} from '../../../data/containers';
import {Store} from '../../../stores/Store';

class EditContainerBucket extends Component {
    state = {
        form:{
            name:this.props.data.name
        }
    }
    handleChange = (e) => {
        let form = {...this.state.form}
        form[e.target.name] = e.target.value;
        this.setState({
          form:form
        });
    }
    update = async() =>{
        let error = false;
        error = (this.state.form.name.length > 3 && error === false)?true:(error !== false)?error:'Name needs to be over 3 charactors';
        error = (this.state.form.name.length < 24 && error === false)?true:(error !== false)?error:'Name needs to be less than 24 charactors';
        if(error)
        {
            this.props.close();
            await updateContainerBucket(this.context.product_id, this.props.data.id, this.state.form).then(()=>{
                this.props.update();
            });
        }
    }
    render(){
        return (
            <Fragment>
                <div className="input-group mb-3">
                    <div className="input-group-prepend">
                        <span className="input-group-text" id="namelabel">Container Bucket Name</span>
                    </div>
                    <input type="text" name='name' required={true} onChange={this.handleChange} value={this.state.form.name} className={(this.state.error && this.state.form.name.length < 3)?"form-control formerror":"form-control"} placeholder="Container Group Name" />
                </div>
                <button className="btn btn-success nomargin floatright space top" onClick={this.update}>Update</button>
            </Fragment>
        )
    }
}
EditContainerBucket.contextType = Store; // This part is important to access context values
export default EditContainerBucket;