import React, {Component, Fragment} from 'react';
import {NavLink} from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faTh, faCubes, faCube, faImages
} from '@fortawesome/free-solid-svg-icons';
import {Store} from "../../stores/Store";

class Sidebar extends Component{
    render(){
        return (
                <nav id='sizesidbar' className="col-1 bg-dark sidebar sidebar-sticky">
                    <ul className="nav flex-column ">
                        <li className="nav-item brand-item " >
                            <img className="d-md-none" alt='logo' src={process.env.REACT_APP_WHITE_LOGO_SMALL} />
                            <span className="d-none d-md-inline">
                                <img alt='logo' style={{width:'40%'}} src={process.env.REACT_APP_WHITE_LOGO} />
                            </span>
                        </li>
                        {(!this.props.nooptions)&&(
                            <Fragment>
                                {(this.context.product_id)&&(
                                    <Fragment>
                                        <li className="nav-item">
                                            <NavLink className="nav-link" exact to={"/product/"+this.context.product_id} activeClassName="active">
                                                <FontAwesomeIcon icon={ faTh } /><br/>
                                                <span className="d-none d-md-inline">
                                                    Dashboard
                                                </span>
                                            </NavLink>
                                        </li>
                                        <li className="nav-item">
                                            <NavLink className="nav-link" exact to={"/product/"+this.context.product_id+'/viewcontainers'} activeClassName="active">
                                                <FontAwesomeIcon icon={ faCube } /><br/>
                                                <span className="d-none d-md-inline">
                                                    View
                                                </span>
                                            </NavLink>
                                        </li>
                                        <li className="nav-item">
                                            <NavLink className="nav-link" exact to={"/product/"+this.context.product_id+'/datacontainers'} activeClassName="active">
                                                <FontAwesomeIcon icon={ faCube } /><br/>
                                                <span className="d-none d-md-inline">
                                                    Data
                                                </span>
                                            </NavLink>
                                        </li>
                                        <li className="nav-item">
                                            <NavLink className="nav-link" exact to={"/product/"+this.context.product_id+'/feeds'} activeClassName="active">
                                                <FontAwesomeIcon icon={ faCubes } /><br/>
                                                <span className="d-none d-md-inline">
                                                    Feeds
                                                </span>
                                            </NavLink>
                                        </li>
                                        <li className="nav-item">
                                            <NavLink className="nav-link" exact to={"/product/"+this.context.product_id+'/content'} activeClassName="active">
                                                <FontAwesomeIcon icon={ faImages } /><br/>
                                                <span className="d-none d-md-inline">
                                                    Content
                                                </span>
                                            </NavLink>
                                        </li>
                                    </Fragment>
                                )}
                            </Fragment>
                        )}
                    </ul>
                </nav>
        );
    }
}
Sidebar.contextType = Store; // This part is important to access context values
export default Sidebar;
