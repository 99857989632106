import axios from 'axios';
import {getHeaders} from '../helpers/getHeaders';
import  {db, auth} from '../firebase';
//// non authenticated functions

export const Login = async (email, password) => {
    let success = '';
        await auth.signInWithEmailAndPassword(email, password).then(async (res)=>{
            success = {
                success:true,
                data:await getUser()
            }
            return 
        }).catch((error)=>{
            success = {
                success:false,
                error:error.message
            }
            return 
        });
    return success;
}
export const RegisterUser = async (form) => {
    let success = {
        success:true
    };
    await auth.createUserWithEmailAndPassword(form.email, form.password).then(async (res)=>{
        res.user.updateProfile({
            displayName:form.f_name+' '+form.l_name
        });
        await db.collection('profiles').doc(res.user.uid).set(
            {
                uid:res.user.uid,
                f_name:form.f_name,
                l_name:form.l_name,
                level:'standard',
                email:form.email
            }
            ).then(()=>{
                auth.currentUser.sendEmailVerification();
                return 
            }).catch((error)=>{
                success = {
                    success:false,
                    error:error.message
                };
                res.user.delete();
                return 
            });
    }).catch((error)=>{
        success = {
            success:false,
            error:error.message
        }
        return 
    });
    return success 
}
export const ResendVerificationEmail = async () => {
    let success = ''
    await auth.currentUser.sendEmailVerification().then((res) => {
        success = {
            success:true
        }
        return
    }).catch((error) => {
        success = {
            success:false,
            error:error.message
        }
        return
    })
    return success 
}
export const ResetPassword = async (form) => {
    let success = '';
    await auth.sendPasswordResetEmail(form.email).then(()=>{
        success= {
            success:true
        }
        return 
    }).catch((error)=>{
        success = {
            success:false,
            error:error.message
        }
        return
    });
    return success;
}
export const Logout = async (context) => {
    auth.signOut();
    context.reset();
}
////// Authenticated Functions
export const getUser = async () => {
    let user = auth.currentUser;
    let returnuser = {};
    if(user){
        let token = await auth.currentUser.getIdToken(true).then(function(idToken) {
            return idToken
          });
        returnuser['uid'] = user.uid;
        returnuser['email'] = user.email;
        returnuser['emailVerified'] = user.emailVerified;
        returnuser['token'] = token;
        await db.collection('profiles').doc(user.uid).get().then((doc) => {
            let data = doc.data();
            returnuser['f_name'] = data.f_name;
            returnuser['l_name'] = data.l_name;
            returnuser['level'] = data.level;
            return true
        })
        return returnuser;
    }else{
        return null;
    }
}
export const deleteMyAccount = async (context) => {
    let uid = await auth.currentUser.uid;
    let success = '';
    await auth.currentUser.delete().then(async (res)=>{
        success = {
            success:true,
        }
        await db.collection('profiles').doc(uid).delete();
    }).catch((error)=>{
        success = {
            success:false,
            error:error.message
        }
    })
    return success;
}
/// these are the standard user updates 
export const updatePassword = async (form) => {
    let user = auth.currentUser;
    return await auth.signInWithEmailAndPassword(user.email, form.password).then(async (res)=>{
        user = auth.currentUser;
        
        return await user.updatePassword(form.new_password).then(() => {
            return {
                success:true
            }
        }).catch((error) => { 
            return {
                success:false,
                error:error.message
            }
        });
    }).catch((error)=>{
        return {
            success:false,
            error:error.message
        }
    });
}

export const updateUser = async (form) => {
    let user = auth.currentUser;
    let success= {}
    let emailupdated = false;
    await auth.signInWithEmailAndPassword(user.email, form.password).then(async (res)=>{
        let updateVars = {};
            updateVars.displayName = form.f_name + ' ' + form.l_name;
            updateVars.email = form.email;
        if(form.email !== auth.currentUser.email)
        {
            await auth.currentUser.updateEmail(form.email);
            updateVars.emailVerified = false;
            auth.currentUser.sendEmailVerification();
            emailupdated = true;
        }
        await auth.currentUser.updateProfile(updateVars).then(async (res)=>{
            await db.collection('profiles').doc(auth.currentUser.uid).update({
                f_name:form.f_name,
                l_name:form.l_name,
                email:form.email
            }).then(()=>{
                return 
            });
            success = {
                success:true
            }
            if(emailupdated === true)
            {
                success.email = true
            }
            return
        }).catch((error)=>{
            success = {
                success:false,
                error:error.message
            }
            return
        });
    }).catch((error)=>{
        success = {
            success:false,
            error:error.message
        }
        return
    });
    return success
}
//// admin functions
/// these are the super user methods for user updates and reports

export const createUser = async (context, form) => {
    let uri = '/createuser';
    let res = await axios.post(process.env.REACT_APP_API_URL+uri, form, {headers:getHeaders(context.token)});
    return await res.data;
}
export const getUsers = async (context) => {
    let uri = '/users';
    let res = await axios.get(process.env.REACT_APP_API_URL+uri, {headers:getHeaders(context.token)}).catch((error)=>{
        context.reset();
        return null;
    });
    return await (res !== null && res !== undefined)?res.data:null;
}
export const adminGetUser = async (context, uid) => {
    let uri = '/user?uid='+uid;
    let res = await axios.get(process.env.REACT_APP_API_URL+uri, {headers:getHeaders(context.token)}).catch((error)=>{
        context.reset();
        return null;
    });
    return await res.data;
}
export const deleteUser = async (context, id) => {
    let uri = '/deleteuser/?uid='+id;
    let res = await axios.delete(process.env.REACT_APP_API_URL+uri, {headers:getHeaders(context.token)});
    return await res.data;
}

export const disableUser = async (context, id) => {
    let uri = '/disableuser/?uid='+id;
    let res = await axios.get(process.env.REACT_APP_API_URL+uri, {headers:getHeaders(context.token)});
    return await res.data;
}
export const enableUser = async (context, id) => {
    let uri = '/enableuser/?uid='+id;
    let res = await axios.get(process.env.REACT_APP_API_URL+uri, {headers:getHeaders(context.token)});
    return await res.data;
}
export const superUpdateUser = async (context, form) => {
    let uri = '/adminuserupdate/';
    let res = await axios.patch(process.env.REACT_APP_API_URL+uri, form, {headers:getHeaders(context.token)});
    return await res.data;
}


export const stats = async (context, days) => {
    let uri = '/stats?days='+days;
    let res = await axios.get(process.env.REACT_APP_API_URL+uri, {headers:getHeaders(context.token)});
    return await res.data;
}
//// apply email check on all update scripts and check the UI can respond correctly