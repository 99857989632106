import React, {Component, Fragment} from 'react';
import PageTop from "../Global/pagetop/";
import NoContent from '../Global/NoContent';
import {Store} from '../../stores/Store';
import Loading from '../Global/Loading';
import {getFeeds} from '../../data/feeds';
import FeedButtons from './FeedButtons';
import FeedCard from './FeedCard';
class Feeds extends Component {
    state = {
        feeds:null
    }
    componentDidMount(){
        this.update();
    }
    update = () =>{
        getFeeds(this.context.product_id).then((res)=>{
            this.setState({
                feeds:res.data
            });
        });
    }
    render(){
        return (this.state.feeds)?(
            <Fragment>
                <PageTop 
                    name={'Feeds'} 
                    breadcrumbs={[
                        {
                            name:'Products',
                            uri:'/'
                        },{
                            name:this.context.product.name,
                            uri:'/product/'+this.context.product_id
                        },{
                            name:'Feeds',
                            uri:'/product/'+this.context.product_id+'/feeds/'
                        }
                    ]}
                    buttons={<FeedButtons 
                                update={this.update}
                            />}
                    />
                <div className='inner'>
                    <div className="row">
                        <div className="col-12">
                        {(this.state.feeds.length > 0)?(
                            <ul className="row mb-3 card-list">
                                {this.state.feeds.map((item, i) => <FeedCard key={i} data={item} update={this.update} />)}
                            </ul>
                        ):<NoContent />}
                        </div>
                    </div>
                </div>
            </Fragment>
        ):(
            <Fragment>
                <PageTop name={'Loading Feeds'} />
                <div className='inner'>
                    <Loading />
                </div>
            </Fragment>
        );
    }
}
Feeds.contextType = Store; // This part is important to access context values
export default Feeds;
