import React, {Component, Fragment} from 'react';
import NoContent from '../Global/NoContent';
import {Store} from '../../stores/Store';
import Loading from '../Global/Loading';
import {updateFeedCategory, deleteFeedCategory, getFeedCategories} from '../../data/feeds';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faPlus, faTrash, faEdit, faEye
} from '@fortawesome/free-solid-svg-icons';
import ModalAlert from '../Global/ModalAlert';
import NewCategory from './NewCategory';
import UpdateCategory from './UpdateCategory';
import {NavLink} from 'react-router-dom';
class Categories extends Component {
    state = {
        categories:null
    }
    componentDidMount(){
        this.update();
    }
    update = () => {
        getFeedCategories(this.context.product_id, this.props.feed_id).then((res)=>{
            this.setState({
                categories:res.data.sort((a, b) => {
                    let a_id = a.sub_cat?a.sub_cat:'';
                    let b_id = b.sub_cat?b.sub_cat:'';
                    //sort by sub_cat if it exists otherwise sort by cat id (causing grouping the cats with sub cats)
                    return (a_id > b_id) ? 1 : (a_id === b_id) ? ((a.id > b.id) ? 1 : -1) : -1
                })
            });
        });
    }
    delete = (id) => {
        deleteFeedCategory(this.context.product_id, this.props.feed_id, id).then(()=>{
            this.update();
        });
    }
    updateCategory = (category_id) =>{
        updateFeedCategory(this.context.product_id, this.props.feed_id, category_id).then((res)=>{
            this.setState({
                categories:res.data
            });
        });
    }
    isSubCat = (item)=>{
        let master_cat = this.state.categories.find(cat => cat.id === item.sub_cat);
        return (master_cat)?true:false;
    }
    hasSubCat = (item)=>{
        let master_cat = this.state.categories.find(cat => cat.sub_cat === item.id);
        return (master_cat)?true:false;
    }
    render(){
        return (this.state.categories)?(
            <Fragment>
                <div className="row morespace bottom">
                    <div className="col-12">
                        <ModalAlert 
                            buttonClassName='boxbutton floatright' 
                            buttonType='none'
                            buttonIcon={<Fragment><FontAwesomeIcon icon={faPlus} /> <span>Category</span></Fragment>} 
                            title={'New Category'} 
                            content={<NewCategory update={this.update} feed_id={this.props.feed_id} />}
                            size='lg'
                        />
                    </div>
                </div>
                <div className="row">
                        <div className="col-12">
                        {(this.state.categories.length > 0)?(
                            <table>
                                <thead className='primary'>
                                    <tr>
                                        <th>Name</th>
                                        <th>Sub Cat</th>
                                        <th>ID</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.categories.map(
                                        (item, id)=>{
                                            return (
                                                <tr key={id} className={(id % 2)?'odd':''}>
                                                    <td>{(!item.sub_cat)&&item.name}</td>
                                                    <td>{this.isSubCat(item)&&item.name}</td>
                                                    <td>{item.id}</td>
                                                    <td>
                                                        <ModalAlert 
                                                            buttonClassName='boxbutton' 
                                                            buttonType='none'
                                                            buttonIcon={<FontAwesomeIcon icon={faEdit} />} 
                                                            title={'Edit Category'} 
                                                            content={<UpdateCategory update={this.update} feed_id={this.props.feed_id} id={item.id} />}
                                                            size='lg'
                                                        />
                                                        <NavLink onClick={()=>{this.props.setCategory(item.id, item.name)}} className="btn btn-default boxbutton" exact to={'/product/'+this.context.product_id+'/feeds/'+this.props.feed_id+'/'+item.id}>
                                                            <FontAwesomeIcon icon={faEye} />
                                                        </NavLink>
                                                        
                                                        {(!this.hasSubCat(item))&&<button onClick={()=>{
                                                            (window.confirm('Deleting this category will remove it and orphen the content. Do you want to continue?'))&&this.delete(item.id);
                                                        }} className='btn btn-default boxbutton'><FontAwesomeIcon icon={faTrash} /></button>}
                                                    </td>
                                                </tr>
                                            )
                                        }
                                    )}
                                </tbody>
                            </table>
                        ):<NoContent />}
                        </div>
                    </div>
            </Fragment>
        ):(
            <Fragment>
                <div className='inner'>
                    <Loading />
                </div>
            </Fragment>
        );
    }
}
Categories.contextType = Store; // This part is important to access context values
export default Categories;
