import React, {Component, Fragment} from 'react';
import PageTop from "../Global/pagetop";
import NoContent from '../Global/NoContent';
import {Store} from '../../stores/Store';
import Loading from '../Global/Loading';
import {getContainers, getContainerBuckets} from '../../data/containers';
import ContainerButtons from './ContainerButtons';
import ContainerCard from './ContainerCard';
import ContainerBuckets from './ContainerBuckets';

class DataContainers extends Component {
    state = {
        containers:null,
        type:'data',
        view:'data_containers',
        container_buckets:[]
    }
    componentDidMount(){
        this.update();
    }
    update = async() =>{
        getContainers(this.context.product_id).then((res)=>{
            this.setState({
                containers:res.data.filter((item, i)=>item.feed_id === null && item.type === 'data')
            });
        });
        getContainerBuckets(this.context.product_id, this.state.type).then((res)=>{
            this.setState({
                container_buckets:res.data
            });
        });
    }
    getContainerAmount = () =>{
        return this.state.containers.filter((item, i)=>item.template === false).length;
    }
    getTemplateAmount = () =>{
        return this.state.containers.filter((item, i)=>item.template === true).length;
    }

    switchTab = (view) =>{
        this.setState({
            view:view
        });
    }
    render(){
        return (this.state.containers)?(
            <Fragment>
                <PageTop 
                    name={'Data Containers'} 
                    breadcrumbs={[
                        {
                            name:'Products',
                            uri:'/'
                        },{
                            name:this.context.product.name,
                            uri:'/product/'+this.context.product_id
                        },{
                            name:'Containers',
                            uri:'/product/'+this.context.product_id+'/containers'
                        }
                    ]}
                    buttons={<ContainerButtons 
                               update={this.update} 
                               type={this.state.type}
                            />}
                />
                <div className='inner'>
                    <div className="row containertabs">
                        <div className="col-12">
                            <ul className="nav nav-pills">
                                <li className="nav-item">
                                    <span className={'click nav-link '+((this.state.view === 'data_containers')&&'active')} onClick={()=>{this.switchTab('data_containers')}}>Containers</span>
                                </li>
                                <li className="nav-item">
                                    <span className={'click nav-link '+((this.state.view === 'data_templates')&&'active')} onClick={()=>{this.switchTab('data_templates')}}>Templates</span>
                                </li>
                                <li className="nav-item">
                                    <span className={'click nav-link '+((this.state.view === 'container_buckets')&&'active')} onClick={()=>{this.switchTab('container_buckets')}}>Buckets</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                    {(this.state.view === 'data_containers')&&
                        <div className="row">
                            <div className="col-12">
                                {(this.getContainerAmount() > 0)?(
                                    <ul className="row mb-3 card-list">
                                        {this.state.containers.map(
                                            (item, i) => {
                                                return (!item.template)?<ContainerCard key={i} data={item} type={this.state.type} update={this.update} />:null
                                            }
                                        )}
                                    </ul>
                                ):<NoContent />}
                            </div>
                        </div>
                    }
                    {(this.state.view === 'data_templates')&&
                        <div className="row">
                            <div className="col-12">
                                {(this.getTemplateAmount() > 0)?(
                                    <ul className="row mb-3 card-list">
                                        {this.state.containers.map(
                                            (item, i) => {
                                                return (item.template)?<ContainerCard template={true} key={i} data={item} type={this.state.type} update={this.update} />:null
                                            }
                                        )}
                                    </ul>
                                ):<NoContent />}
                            </div>
                        </div>
                    }
                    {(this.state.view === 'container_buckets')&&
                        <div className="row">
                            <div className="col-12">
                                {
                                    (this.state.container_buckets.length > 0)?(
                                        <ul className="row mb-3 card-list">
                                            {this.state.container_buckets.map(
                                                (item, i) => {
                                                    return <ContainerBuckets key={i} update={this.update} type={this.state.type} data={item} />
                                                }
                                            )}
                                        </ul>
                                    ):<NoContent />
                                }
                            </div>
                        </div>
                    }
                </div>
            </Fragment>
        ):(
            <Fragment>
                <PageTop name={'Loading Containers'} />
                <div className='inner'>
                    <Loading />
                </div>
            </Fragment>
        );
    }
}
DataContainers.contextType = Store; // This part is important to access context values
export default DataContainers;
