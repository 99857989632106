import axios from 'axios';

export const exthook = async (exthook) => {
    axios.get(exthook)
      .then(res => {
        alert('Hooked called with 200 result');
      }).catch((error)=>{
          console.log(error);
        alert('Error calling hook');
      })
}