export const numberWithCommas = (x) => {
    return (+x.toFixed(2)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}
export const humanTime = (date) => {
    let year = date.substring(0, 4);
    let month = date.substring(4, 6);
    let day = date.substring(6, 8);
    return day+'/'+month+'/'+year;
}
export const getFileName = (url) => {
    let filename = url.substring(url.lastIndexOf('/')+1);
    return filename;
}
export function ucfirst(string) 
{
    return string.charAt(0).toUpperCase() + string.slice(1);
}
export function truncateWords(str, no_words) {
    let wordlength = str.split(' ').length;
    let addDots = (wordlength > no_words)?'...':'';
    return str.split(" ").splice(0,no_words).join(" ")+addDots;
}
export function isNumber(value){
    let validNumber = new RegExp(/^\d*\.?\d*$/);
    return validNumber.test(value);
}

export function findArrayMatch(arr1, arr2) {
    var arr = [];  // Array to contain match elements
    for(var i=0 ; i<arr1.length ; ++i) {
      for(var j=0 ; j<arr2.length ; ++j) {
        if(arr1[i] === arr2[j]) {    // If element is in both the arrays
          arr.push(arr1[i]);        // Push to arr array
        }
      }
    }
    return arr;  // Return the arr elements
  }

  export function arrayMatch(arr1, arr2) {
    var arr = [];  // Array to contain match elements
    for(var i=0 ; i<arr1.length ; ++i) {
      for(var j=0 ; j<arr2.length ; ++j) {
        if(arr1[i] === arr2[j]) {    // If element is in both the arrays
          arr.push(arr1[i]);        // Push to arr array
        }
      }
    }
    return (arr.length > 0)?true:false;  // Return the arr elements
  }