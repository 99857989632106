import React from 'react';

const Select = (props) => {
    return (
        <div className="input-group mb-3">
            <div className="input-group-prepend">
                <div className="input-group-text">{props.item.name}</div>
            </div>
            <select name={props.item.id} required className='form-control' onChange={(e)=>{props.handleChange(e, 'select')}} value={props.item.value}>
                <option value=''>not selected</option>
                {props.item.select.map((item, i)=>{
                    return(
                        <option key={i} value={item.value}>
                            {item.title}
                        </option>
                    )
                })}
            </select>
        </div>
    )
}
export default Select;