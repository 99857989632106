import React from 'react';
import {NavLink} from 'react-router-dom';
//componants

const PageTop = (props) => {
    let breadcrumbLen = (props.breadcrumbs)?props.breadcrumbs.length:0;
        return (
            <div className="row PageTop">
                <div className="col-6">
                    {(props.breadcrumbs)&&(<div className='breadcrumbs'>{props.breadcrumbs.map((item, i)=>{
                        return (
                            <NavLink key={i} className="nav-link" exact to={item.uri} activeClassName="active">
                                <span>{(item.name)} {(breadcrumbLen > (i + 1))?'> ':''}</span>
                            </NavLink>
                        )
                    })}</div>)}
                </div>
                <div className="col-6">
                    {props.buttons}
                </div>
            </div>

        )
}
export default PageTop;