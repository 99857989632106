import React, {Component, Fragment} from 'react';
import {share, unshare, shared} from '../../data/products';
import {Store} from '../../stores/Store';
import Loading from '../Global/Loading';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faTimes
} from '@fortawesome/free-solid-svg-icons';
class Share extends Component {
    state = {
        form:{
            email:''
        },
        shared:null
    }
    handleChange = (e) => {
        let form = {...this.state.form}
        form[e.target.name] = e.target.value;
        this.setState({
          form:form
        });
    }
    componentDidMount(){
        this.getshared();
    }
    getshared = () => {
        shared(this.context, this.props.product_id).then(async (res)=>{
            await this.setState({
                shared:res.data
            });
        });
    }
    share = async() =>{
        let error = false;
        error = (this.state.form.email.length > 3 && error === false)?true:(error !== false)?error:'Email needs to be over 3 charactors';
        if(error === true)
        {
            share(this.context, this.state.form.email, this.props.product_id).then(()=>{
                this.props.update();
                this.props.close();
            }).catch((error)=>{
                console.log(error);
            });
        }else{
            alert('issue')
        }
    }
    unshareuser = async(email) =>{
        unshare(this.context, email, this.props.product_id).then(()=>{
            this.getshared();
        });
    }
    render(){
        return (
            <Fragment>
                <div className="input-group mb-3">
                    <div className="input-group-prepend">
                        <span className="input-group-text" id="namelabel">Email</span>
                    </div>
                    <input type="text" name='email' required={true} onChange={this.handleChange} value={this.state.form.name} className={(this.state.error && this.state.form.name.length < 3)?"form-control formerror":"form-control"} placeholder="Enter Email of the person you wish to share with" />
                    <button className="btn btn-success nomargin floatright" onClick={this.share}>Share</button>
                </div>
                {(this.state.shared)?(this.state.shared.length > 0)?(
                <div className='card'>
                    <div className='card-header'>
                        Shared With:
                    </div>
                    <div className='card-body'>
                        <ul className="list-group">
                        {this.state.shared.map((item)=>{
                            return (
                                <li className="list-group-item">{item.email}
                                    <div onClick={()=>{this.unshareuser(item.email)}} className="click floatright false" title="Amount of Pages"><FontAwesomeIcon icon={faTimes} /></div>
                                </li>
                            )
                        })}
                        </ul>
                    </div>
                </div>):null
                :<Loading />}
            </Fragment>
        )
    }
}
Share.contextType = Store; // This part is important to access context values
export default Share;