import React, {useContext, useEffect, useState} from 'react';
import {getFeedContainers} from '../../../../data/containers';
import {Store} from '../../../../stores/Store';
const FeedItem = (props) => {
    const [feedItems, setFeedItems] = useState(null)
    const context = useContext(Store);
    const [selected, setSelected] = useState(props.item.value);
    useEffect(()=>{
        if(props.item.linked){
            getFeedContainers(context.product_id, props.item.linked).then((responce)=>{
                setFeedItems(responce.data.filter(item=>item.template === false));
            });
        }
    }, []);
    return (feedItems)
    ?
        <div className="input-group mb-3">
            <div className="input-group-prepend">
                <div className="input-group-text">{props.item.name}</div>
            </div>
            <select name={props.item.id} required className='form-control' onChange={(e)=>{setSelected(e.target.value);props.handleChange(e, 'select')}} value={selected}>
                <option value=''>not selected</option>
                {feedItems.map((item, i)=>{
                    return(
                        <option key={i} value={props.item.linked+'!link!'+item.id}>
                            {item.name}
                        </option>
                    )
                })}
            </select>
        </div>:'No Feed Linked'
}
export default FeedItem;