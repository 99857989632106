import React, {Component} from 'react';
/// templates
import {BrowserRouter} from 'react-router-dom';
import LoggedIn from "./templates/LoggedIn";
import LoggedOut from "./templates/LoggedOut";
import {Modal, ModalHeader, ModalBody } from 'reactstrap';
import "./appstyles/bootstrap/bootstrap.min.css";
import "./appstyles/env.scss";
///store
import {Provider, Session} from "./stores/Store";

class App extends Component {
    constructor(props){
        super(props);
        this.state = Session ? this.setupSession():{
            update:this.update,
            reset:this.resetContext,
            alert:this.alert,
            token:null,
            access:'standard',
            modal:false,
            alertContent:''
        }
    }
    setupSession = () => {
        let Obj = JSON.parse(Session);
        Obj.update = this.update;
        Obj.alert = this.alert;
        Obj.reset = this.resetContext;
        return Obj;
    }
    update = (obj, callback) => {
        this.setState(obj,() => {
            sessionStorage.setItem('state', JSON.stringify(this.state));
            typeof callback === 'function' && callback();
        });
    }
    resetContext = () => {
        sessionStorage.clear('state');
        this.update({
            token:null
        })
    }
    alert = async (alertContent) => {
        await this.setState({
            alertContent:alertContent
        });
        this.toggle();
    }
    toggle = () => {
        this.setState({
            modal: (this.state.modal)?false:true
        });
    }
    render(){
        return (
            <BrowserRouter>
                <Provider value={this.state}>
                    {(this.state.token)?<LoggedIn/>:<LoggedOut/>}
                    <Modal size={'sm'} isOpen={this.state.modal} toggle={this.state.toggle}>
                        <ModalHeader toggle={this.toggle}>Alert</ModalHeader>
                        <ModalBody>
                            {this.state.alertContent}
                        </ModalBody>
                    </Modal>
                </Provider>
            </BrowserRouter>
        )
    }
}
export default App;
