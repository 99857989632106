import React, {Component, Fragment} from 'react';
import PageTop from "../Global/pagetop/";
import DashboardButtons from './DashboardButtons';
import NoContent from './NoContent';
import Loading from '../Global/Loading';
import {getProducts, getProductsSharedWithMe} from '../../data/products';
import ProductCard from './ProductCard/';
import {Store} from '../../stores/Store';

class Dashboard extends Component {
    state = {
        products:null,
        shared_products:null
    }
    componentDidMount(){
        this.update();
        this.context.update({
            product_id:undefined
        });
    }
    update = async () => {
        
        getProducts().then((res)=>{
            this.setState({
                products:(res.success === true)?res.data:[]
            });
        }).catch(error =>{
            this.setState({
                products:[]
            });
        });
        getProductsSharedWithMe().then((res)=>{
            this.setState({
                shared_products:(res.success === true)?res.data:[]
            });
        }).catch(error =>{
            this.setState({
                products:[]
            });
        });
    }
    render(){
        return (
            <Fragment>
                <PageTop name='Dashboard' buttons={<DashboardButtons update={this.update} />} />
                <div className='inner'>
                    <div className='row'>
                        <div className='col-12 '>
                            <div className='productContainer card'>
                                <div className='card-header'>
                                My Products 
                                </div>
                                <div className='card-body'>
                                    {
                                        (this.state.products !== null && this.state.products !== undefined && this.state.products !== false)
                                        ?
                                            (this.state.products.length > 0)?(
                                                <Fragment>
                                                    <ul className="row mb-3 card-list">
                                                        {this.state.products.map((item, i)=>{
                                                            return <ProductCard update={this.update} key={i} data={item} />
                                                        })}
                                                    </ul>
                                                </Fragment>
                                            ):<NoContent />
                                        :<Loading />
                                    }
                                </div>
                            </div>
                        </div>
                        <div className='col-12 '>
                            <div className='productContainer card'>
                                <div className='card-header'>
                                    Products Shared With Me
                                </div>
                                <div className='card-body'>
                                    {
                                        (this.state.shared_products !== null && this.state.shared_products !== undefined && this.state.shared_products !== false)
                                        ?
                                            (this.state.shared_products.length > 0)?(
                                                <Fragment>
                                                    <ul className="row mb-3 card-list">
                                                        {this.state.shared_products.map((item, i)=>{
                                                            return <ProductCard update={this.update} key={i} data={item} />
                                                        })}
                                                    </ul>
                                                </Fragment>
                                            ):<NoContent />
                                        :<Loading />
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        );
    }
}
Dashboard.contextType = Store; // This part is important to access context values
export default Dashboard;
