import React from 'react';
import ModalAlert from '../../../Global/ModalAlert';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faEdit
} from '@fortawesome/free-solid-svg-icons';
import Files from '../../../Content/Files';

const Pdf = (props) => {
    return (
        <div className="input-group mb-3">
            <div className="input-group-prepend">
                <div className="input-group-text">{props.item.name}</div>
            </div>
            <input 
                type='text' name={props.item.id} 
                className='form-control' 
                readOnly
                placeholder={(props.item.placeholder)?props.item.placeholder:null}
                value={(props.currentValue !== null && props.currentValue !== undefined)?props.currentValue:props.item.value} 
            />
            <ModalAlert 
                buttonClassName=' btn btn-success' 
                buttonType='none'
                buttonIcon={<FontAwesomeIcon icon={faEdit} />} 
                title={'Select'} 
                content={<Files use={props.imageUpdate} inputid={props.item.id} filter='application/pdf' />}
                size='lg'
            />
        </div>
    )
}
export default Pdf;