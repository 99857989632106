import React, {Component, Fragment} from 'react';
import {createContainer, getContainers, getContainerBuckets} from '../../data/containers';
import {Store} from '../../stores/Store';
import Loading from '../Global/Loading';
import {getFeedCategories, getFeeds} from '../../data/feeds'; 

import Select from 'react-select'
import makeAnimated from 'react-select/animated';

class NewContainer extends Component {
    state = {
        form:{
            name:'',
            type:(this.props.type)?this.props.type:'view',
            template:(this.props.template === true)?true:false,
            feeds:(this.props.feed_id)?[this.props.feed_id]:[],
            global:false,
            template_id:null,
            categories:[]
        },
        feed_id:(this.props.feed_id)?this.props.feed_id:null,
        templates:null,
        categories:[],
        feeds:[],
        buckets:[]
    }
    componentDidMount(){
        getContainers(this.context.product_id).then((res)=>{
            let templates = res.data.filter(
                (item)=>{ if(this.props.feed_id){  
                        return item.template === true && (item.feed_id === this.props.feed_id || item.feed_id === 'global')
                    }else{
                        return item.template === true && item.feed_id === null
                    }
                });
            this.setState({
                templates:templates
            });
        });
        if(this.state.feed_id)
        {
            getFeeds(this.context.product_id).then(async (res)=>{
                this.setState(
                    {
                        feeds:res.data
                    }
                );
                for(let i = 0; this.state.feeds.length > i; i++){
                    let getcats = await getFeedCategories(this.context.product_id, this.state.feeds[i].id).then((res)=>{
                        for(let b = 0; res.data.length > b; b++){
                            res.data[b].feed_name = this.state.feeds[i].name;
                        }
                        return res.data
                    });
                    this.setState(
                        {
                            categories:this.state.categories.concat(getcats)
                        }
                    )
                }
                
                return null;
            });
        }else{
            getContainerBuckets(this.context.product_id, this.state.form.type).then((res)=>{
                this.setState(
                    {
                        buckets:res.data
                    }
                )
            });
        }
    }
    processCategories = () => {
        let cats = [];
        for(let i = 0; this.state.categories.length > i; i++){
            let feed = this.state.feeds.find(element => element.id === this.state.categories[i].feed_id);
            if(this.state.form.feeds.includes(this.state.categories[i].feed_id)){
                cats.push({
                    value:this.state.categories[i].feed_id+"||"+this.state.categories[i].id, 
                    label:((feed)?feed.name:'')+' > '+
                        ((this.state.categories[i].sub_cat)
                        ?
                            (this.state.categories.find(
                                item=>{ 
                                    return this.state.categories[i].sub_cat === item.id
                                }
                            )||{name:'Unknown Cat'}).name+' > '+this.state.categories[i].name
                        :
                            this.state.categories[i].name)
                }); 
            }
            
        }
        return cats.sort((a, b) => {
            let a_id = a.label;
            let b_id = b.label;
            //sort by sub_cat if it exists otherwise sort by cat id (causing grouping the cats with sub cats)
            return (a_id > b_id) ? 1 : (a_id === b_id) ? ((a.id > b.id) ? 1 : -1) : -1
        });;
    }
    processFeeds = () => {
        let feeds = [];
        for(let i = 0; this.state.feeds.length > i; i++){
            feeds.push({value:this.state.feeds[i].id, label:this.state.feeds[i].name});
        }
        return feeds;
    }
    processBuckets = () => {
        let obj = [];
        for(let i = 0; this.state.buckets.length > i; i++){

            obj.push({value:this.state.buckets[i].id, label:this.state.buckets[i].name}); 
        }
        return obj
    }
    handleFeedChange = (arr) => {

        let array = (arr)?arr:[];
        let form = {...this.state.form}
        let feed_obj = [];
        let cat_obj = [];
        for(let i = 0; array.length > i; i++){
            let cats = this.processCategories().filter((item)=>{return item.value.includes(array[i].value)});
            for(let b = 0; cats.length > b; b++){
                if(form.categories.includes(cats[b].value.split('||')[1])){
                    cat_obj.push(cats[b].value.split('||')[1]);
                }
            }
            let feed_id = feed_obj.find(element=>element===array[i].value);
            (!feed_id)&&feed_obj.push(array[i].value);
        }
        form.feeds = feed_obj;
        form.categories = cat_obj;
        this.setState(
            {
                form:form
            }
        );
    }
    handleCategoryChange = (arr) => {
        let array = (arr)?arr:[];
        let form = {...this.state.form}
        let feed_obj = [];
        let cat_obj = [];
        for(let i = 0; array.length > i; i++){
            let vals = array[i].value.split('||');
            (vals[1])&&cat_obj.push(vals[1]);
            let feed_id = feed_obj.find(element=>element===vals[0]);
            (!feed_id)&&feed_obj.push(vals[0]);
        }
        form.categories = cat_obj;
        form.feeds = form.feeds.concat(feed_obj);
        this.setState(
            {
                form:form
            }
        );
    }
    handleBucketChange = (arr) => {
        let array = (arr)?arr:[];
        let form = {...this.state.form}
        let ret_obj = [];
        for(let i = 0; array.length > i; i++){
            ret_obj.push(array[i].value);
        }
        form.buckets = ret_obj;
        this.setState(
            {
                form:form
            }
        );
    }
    handleGlobalChange = (e) =>{
        let form = {...this.state.form}
        form[e.target.name] = (e.target.value === "true")?true:false;
        this.setState({
          form:form
        });
    }
    handleChange = (e) => {
        let form = {...this.state.form}
        form[e.target.name] = e.target.value;
        this.setState({
          form:form
        });
    }
    create = async() =>{
        let error = false;
        error = (this.state.form.name.length > 3 && error === false)?true:(error !== false)?error:'Name needs to be over 3 charactors';
        error = (this.state.form.name.length < 24 && error === false)?true:(error !== false)?error:'Name needs to be less than 24 charactors';
        if(error)
        {
            createContainer(this.context.product_id, this.state.form, this.state.feed_id).then(()=>{
                this.props.update();
                this.props.close();
            });
        }
    }
    render(){
        return (this.state.templates !== null)?(
            <Fragment>
                <div className="input-group mb-3">
                    <div className="input-group-prepend">
                        <span className="input-group-text" id="namelabel">{(this.props.template)?'Template':'Container'} Name</span>
                    </div>
                    <input 
                        type="text" 
                        name='name' 
                        required={true} 
                        onChange={this.handleChange} 
                        value={this.state.form.name} 
                        className={(this.state.error && this.state.form.name.length < 3)?"form-control formerror":"form-control"} 
                        placeholder={((this.props.template)?'Template':'Container')+' Name'} 
                    />
                </div>
                {(this.props.template)&&<div className="input-group mb-3">
                    <div className="input-group-prepend">
                        <span className="input-group-text" id="namelabel">Global</span>
                    </div>
                    <select className='form-control' name='global' onChange={this.handleGlobalChange} value={this.state.form.global}>
                        <option value='true'>true</option>
                        <option value='false'>false</option>
                    </select>
                </div>}
                <div className="input-group mb-3">
                    <div className="input-group-prepend">
                        <span className="input-group-text" id="namelabel">Use Template</span>
                    </div>
                    <select className='form-control' name='template_id' onChange={this.handleChange} value={(this.state.form.template_id)?this.state.form.template_id:''}>
                        <option value=''>none</option>
                        {
                            this.state.templates.map((item, i)=>{
                                if(item.type === this.props.type){
                                    return (this.state.feed_id === null)
                                    ?
                                        (item.feed_id === null && item.template)&&<option key={i} value={item.id}>{item.name}</option>
                                    :
                                        ((item.feed_id === this.state.feed_id || item.feed_id === 'global')  && item.template)&&<option key={i} value={item.id}>{item.name}</option>
                                }else{
                                    return (this.state.feed_id !== null)&&((item.feed_id === this.state.feed_id || item.feed_id === 'global'))&&<option key={i} value={item.id}>{item.name}</option>
                                }
                            })
                        }
                    </select>

                </div>
                {
                    (!this.state.feed_id && !this.props.template)&&<Select 
                        placeholder='Select Buckets' 
                        width='100%'
                        isMulti
                        closeMenuOnSelect={false}
                        components={makeAnimated()}
                        options={this.processBuckets()} 
                        onChange={this.handleBucketChange}
                    />
                }
                {
                    (this.state.feed_id && !this.props.template)&&<Fragment>
                        <br/>
                        <Select 
                            placeholder='Select Feeds' 
                            width='100%'
                            isMulti
                            closeMenuOnSelect={false}
                            components={makeAnimated()}
                            value={this.processFeeds().filter((item)=>{ console.log(this.processFeeds()); return this.state.form.feeds.includes(item.value)})}
                            options={this.processFeeds()} 
                            onChange={this.handleFeedChange}
                        />
                    </Fragment>
                }
                {
                    (this.state.feed_id && !this.props.template)&&<Fragment>
                        <br/>
                        <Select 
                            placeholder='Select categories' 
                            width='100%'
                            isMulti
                            closeMenuOnSelect={false}
                            components={makeAnimated()}
                            value={this.processCategories().filter((item)=>{return this.state.form.categories.includes(item.value.split('||')[1])})}
                            options={this.processCategories()} 
                            onChange={this.handleCategoryChange}
                        />
                    </Fragment>
                }
                <button className="btn btn-success nomargin floatright space top" onClick={this.create}>Create</button>
            </Fragment>
        ):<Loading/>
    }
}
NewContainer.contextType = Store; // This part is important to access context values
export default NewContainer;