import React, {Component, Fragment} from 'react';
import {cloneContainerGroup} from '../../../data/clones';
import {Store} from '../../../stores/Store';

class CloneContainerGroup extends Component {
    state = {
        form:{
            name:'',
            slug:'',
            clone_id:this.props.data.id
        }
    }
    componentDidMount(){
        (!this.props.data.id)&&this.props.close();
    }
    handleChange = (e) => {
        let form = {...this.state.form}
        if(e.target.name === 'slug'){
            form[e.target.name] = e.target.value.replace(/[^a-zA-Z0-9_-]/g,'');
            if(form[e.target.name] === this.props.data.slug)
            {
                form[e.target.name] = '';
            }
        }else{
            form[e.target.name] = e.target.value;
        }
        this.setState({
          form:form
        });
    }
    create = async() =>{
        let error = false;
        error = (this.state.form.name.length > 3 && error === false)?true:(error !== false)?error:'Name needs to be over 3 charactors';
        error = (this.state.form.name.length < 24 && error === false)?true:(error !== false)?error:'Name needs to be less than 24 charactors';
        error = (this.state.form.slug.length > 3 && error === false)?true:(error !== false)?error:'Slug needs to be over 3 charactors';
        error = (this.state.form.slug.length < 24 && error === false)?true:(error !== false)?error:'Slug needs to be less than 24 charactors';
        if(error)
        {
            cloneContainerGroup(this.context.product_id, this.props.container_id, this.state.form).then(()=>{
                this.props.update();
                this.props.close();
            });
        }
    }
    render(){
        return (
            <Fragment>
                <div className="input-group mb-3">
                    <div className="input-group-prepend">
                        <span className="input-group-text" id="namelabel">Group Name</span>
                    </div>
                    <input type="text" name='name' required={true} onChange={this.handleChange} value={this.state.form.name} className={(this.state.error && this.state.form.slug.length < 3)?"form-control formerror":"form-control"} placeholder="Group Name" />
                </div>
                <div className="input-group mb-3">
                    <div className="input-group-prepend">
                        <span className="input-group-text" id="namelabel">Group Slug</span>
                    </div>
                    <input type="text" name='slug' required={true} onChange={this.handleChange} value={this.state.form.slug} className={(this.state.error && this.state.form.slug.length < 3)?"form-control formerror":"form-control"} placeholder="Slug (A-Za-z_-)" />
                </div>
                <button className="btn btn-success nomargin floatright" onClick={this.create}>Create</button>
            </Fragment>
        )
    }
}
CloneContainerGroup.contextType = Store; // This part is important to access context values
export default CloneContainerGroup;