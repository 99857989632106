import  {db} from '../firebase';
import {deleteContainer, getContainers} from './containers';

export const getFeeds = async (product_id) => {
    let responce = {
        success:true,
        data:[]
    }
    await db.collection('products').doc(product_id).collection('feeds').get().then(snapshot => {
        let feeds = [];
        if(!snapshot.empty)
        {
            snapshot.forEach(doc => {
                let feed =doc.data();
                feed.id = doc.id;
                feeds.push(feed)
            });
            responce.data = feeds;
        }
        return
    }).catch((error)=>{
        responce.success = false;
        responce.error = error;
        delete responce.data;
        return 
    });

    return responce;
}
export const getFeed = async (product_id, feed_id) => {
    let responce = {
        success:true,
        data:[]
    }
    await db.collection('products').doc(product_id).collection('feeds').doc(feed_id).get().then(doc => {
        let data = doc.data();
        responce.data = data    
        return
    }).catch((error)=>{
        responce.success = false;
        responce.error = error;
        delete responce.data;
        return 
    });
    return responce;
}
export const createFeed = async (product_id, form) => {
    let responce = {
        success:true,
        data:[]
    }
    await db.collection('products').doc(product_id).collection('feeds').add(
        {
            name:form.name,
            view:(form.view)?form.view:'',
            active:true,
            created:new Date().getTime()
        }
    ).then(ref => {
        responce.id = ref.id
        responce.data = ref
    }).catch((error)=>{
        responce.success = false;
        responce.error = error;
        delete responce.data;
        return 
    });
    return responce;
}
export const createFeedCategory = async (product_id, feed_id, form) => {
    let responce = {
        success:true,
        data:[]
    }
    await db.collection('products').doc(product_id).collection('feeds').doc(feed_id).collection('categories').add(
        {
            name:form.name,
            view:(form.view)?form.view:null,
            sub_cat:(form.category)?form.category:null,
            active:true,
            last_updated:new Date().getTime(),
            created:new Date().getTime()
        }
    ).then(ref => {
        responce.id = ref.id
        responce.data = ref
    }).catch((error)=>{
        responce.success = false;
        responce.error = error;
        delete responce.data;
        return 
    });
    return responce;
}
export const getFeedCategories = async (product_id, feed_id) => {
    let responce = {
        success:true,
        data:[]
    }
    await db.collection('products').doc(product_id).collection('feeds').doc(feed_id).collection('categories').get().then(snapshot => {
        let feedcategories = [];
        if(!snapshot.empty)
        {
            snapshot.forEach(doc => {
                let cat = doc.data();
                cat.id = doc.id;
                cat.feed_id = feed_id;
                feedcategories.push(cat);
            });
            responce.data = feedcategories;
        }
        return
    }).catch((error)=>{
        responce.success = false;
        responce.error = error;
        delete responce.data;
        return;
    });
    return responce;
}
export const getFeedCategory = async (product_id, feed_id, category_id) => {
    let responce = {
        success:true,
        data:[]
    }
    await db.collection('products').doc(product_id).collection('feeds').doc(feed_id).collection('categories').doc(category_id).get().then(doc => {
        let data = doc.data();
        responce.data = data ;
        return
    }).catch((error)=>{
        responce.success = false;
        responce.error = error;
        delete responce.data;
        return;
    });
    return responce;
}
export const updateFeedCategory = async (product_id, feed_id, category_id, form) => {
    let responce = {
        success:true,
        data:[]
    }
    await db.collection('products').doc(product_id).collection('feeds').doc(feed_id).collection('categories').doc(category_id).update(
        {
            name:form.name,
            view:(form.view)?form.view:'',
            active:true,
            sub_cat:(form.category)?form.category:null,
            last_updated:new Date().getTime()
        }
    ).then(ref => {
        responce.data = ref
    }).catch((error)=>{
        responce.success = false;
        responce.error = error;
        delete responce.data;
        return 
    });
    return responce;
}
export const deleteFeedCategory = async (product_id, feed_id, category_id) => {
    let responce = {
        success:true
    }
    await db.collection('products').doc(product_id).collection('feeds').doc(feed_id).collection('categories').doc(category_id).delete().catch((error)=>{
        responce.success = false;
        responce.error = error;
        return 
    });
    await db.collection('products').doc(product_id).collection('containers')
    .where("category", "array-contains", category_id)
    .get().then((snapshot)=>{
        if(!snapshot.empty)
        {
            snapshot.forEach(doc => {
                let container = doc.data();
                if(container.category && container.category.length > 0){
                    let cat = container.category.filter(item=>item !== category_id);
                    db.collection('products').doc(product_id).collection('containers').doc(doc.id).update(
                        {
                            category:cat
                        }
                    )
                }
            });
        };
    });
    return responce;
}
export const updateFeed = async (product_id, feed_id, form) => {
    let responce = {
        success:true,
        data:[]
    }
    await db.collection('products').doc(product_id).collection('feeds').doc(feed_id).update(
        {
            name:form.name,
            view:(form.view)?form.view:'',
            active:true,
            edited:new Date().getTime(),
        }
    ).then(ref => {
        responce.data = ref
    }).catch((error)=>{
        responce.success = false;
        responce.error = error;
        delete responce.data;
        return 
    });
    return responce;
}
export const toggleFeed = async (product_id, feed_id, state) => {
    let responce = {
        success:true,
        data:[]
    }
    await db.collection('products').doc(product_id).collection('feeds').doc(feed_id).update(
        {
            active:!state,
        }
    ).then(ref => {
        responce.data = ref
    }).catch((error)=>{
        responce.success = false;
        responce.error = error;
        delete responce.data;
        return 
    });
    return responce;
}

export const deleteFeed = async (product_id, feed_id) => {
    let responce = {
        success:true
    }
    await db.collection('products').doc(product_id).collection('feeds').doc(feed_id).delete().catch((error)=>{
        responce.success = false;
        responce.error = error;
        return 
    });
    await getContainers(product_id).then((res)=>{
        for(let i =0; res.data.length > i; i++)
        {
            if(res.data[i].feed_id === feed_id)
            {
                deleteContainer(product_id, res.data[i].id);
            }
        }
    });
    return responce;
}
export const updateFeedGroup = async (product_id, feed_id, group_id, form) => {
    let responce = {
        success:true,
        data:[]
    }
    await db.collection('products').doc(product_id).collection('feeds').doc(feed_id).collection('groups').doc(group_id).update(
        {
            name:form.name,
            slug:form.slug,
            updated:new Date().getTime(),
        }
    ).then(ref => {
        responce.data = ref
    }).catch((error)=>{
        responce.success = false;
        responce.error = error;
        delete responce.data;
        return 
    });
    return responce;

}
export const getFeedGroups = async (product_id, feed_id) => {
    let responce = {
        success:true,
        data:[]
    }
    await db.collection('products').doc(product_id).collection('feeds').doc(feed_id).collection('groups').get().then(snapshot => {
        let feedgroups = [];
        if(!snapshot.empty)
        {
            snapshot.forEach(doc => {
                let group = doc.data();
                group.id = doc.id;
                feedgroups.push(group)
            });
            responce.data = feedgroups;
        }
        return
    }).catch((error)=>{
        responce.success = false;
        responce.error = error;
        delete responce.data;
        return;
    });
    return responce;
}
export const createFeedGroup = async (product_id, feed_id, form) => {
    let responce = {
        success:true,
        data:[]
    }
    let groupslength = 0;
    await getFeedGroups(product_id, feed_id).then((res)=>{
        groupslength = (res.data)?(res.data.length):0;
    });
    await db.collection('products').doc(product_id).collection('feeds').doc(feed_id).collection('groups').add(
        {
            name:form.name,
            slug:form.slug,
            position:groupslength,
            created:new Date().getTime(),
        }
    ).then(ref => {
        responce.data = ref
    }).catch((error)=>{
        responce.success = false;
        responce.error = error;
        delete responce.data;
        return 
    });
    return responce;
}
export const deleteFeedGroup = async (product_id, feed_id, group_id) => {
    let responce = {
        success:true
    }
    await db.collection('products').doc(product_id).collection('feeds').doc(feed_id).collection('groups').doc(group_id).delete().catch((error)=>{
        responce.success = false;
        responce.error = error;
        return 
    });
    await db.collection('products').doc(product_id).collection('feeds').doc(feed_id).collection('items').get().then((snapshot)=>{
        if(!snapshot.empty)
        {
            snapshot.forEach(doc => {
                let data = doc.data();
                if(data.group_id === group_id)
                {
                    doc.ref.delete();
                }
            });
        }
        return
    }).catch((error)=>{
        responce.success = false;
        responce.error = error;
        return 
    });
    return responce;
}
export const repositionFeedGroup = async (product_id, feed_id, group_id, newPosition) => {
    let responce = {
        success:true
    }
    let group = await getFeedGroups(product_id, feed_id);
    let current = group.data.find((item)=>{ return item.id === group_id });
    let previous = (newPosition < current.position)?group.data.find((item)=>{ return item.position === newPosition}):null;
    let next = (newPosition > current.position)?group.data.find((item)=>{ return item.position === newPosition}):null;
    if(current !== null)
    {
        if(previous !== null && previous !== undefined && newPosition < current.position)
        {
            await db.collection('products').doc(product_id).collection('feeds').doc(feed_id).collection('groups').doc(previous.id).update(
                {
                    position:(newPosition+1),
                }
            );
        }else if(next !== null && next !== undefined && newPosition > current.position){
            await db.collection('products').doc(product_id).collection('feeds').doc(feed_id).collection('groups').doc(next.id).update(
                {
                    position:(newPosition-1),
                }
            );
        }
        await db.collection('products').doc(product_id).collection('feeds').doc(feed_id).collection('groups').doc(group_id).update(
            {
                position:newPosition,
            }
        )
    }
    return responce;
}
export const createFeedItem = async (product_id, feed_id, form) => {
    let responce = {
        success:true,
        data:[]
    }
    let groupitems = await getFeedGroupItems(product_id, feed_id, form.group);
    let position = (groupitems.data)?(groupitems.data.length):0;
    await db.collection('products').doc(product_id).collection('feeds').doc(feed_id).collection('items').add(
        {
            name:form.name,
            slug:form.slug,
            type:form.type,
            placeholder:(form.placeholder)?form.placeholder:null,
            muted:(form.muted)?form.muted:null,
            group_id:(form.group)?form.group:null,
            position:position,
            created:new Date().getTime()
        }
    ).then(ref => {
        responce.data = ref
    }).catch((error)=>{
        responce.success = false;
        responce.error = error;
        delete responce.data;
        return 
    });
    return responce;
}
export const repositionFeedItem = async (product_id, feed_id, item_id, group_id, newPosition) => {
    let responce = {
        success:true
    }
    let groupItems = await getFeedGroupItems(product_id, feed_id, group_id);
    let current = groupItems.data.find((item)=>{ return item.id === item_id });
    let previous = (newPosition < current.position)?groupItems.data.find((item)=>{ return item.position === newPosition}):null;
    let next = (newPosition > current.position)?groupItems.data.find((item)=>{ return item.position === newPosition}):null;

    if(current !== null)
    {

        if(previous !== null && previous !== undefined && newPosition < current.position)
        {
            await db.collection('products').doc(product_id).collection('feeds').doc(feed_id).collection('items').doc(previous.id).update(
                {
                    position:(newPosition+1),
                }
            );
        }else if(next !== null && next !== undefined && newPosition > current.position){
            await db.collection('products').doc(product_id).collection('feeds').doc(feed_id).collection('items').doc(next.id).update(
                {
                    position:(newPosition-1),
                }
            );
        }
        await db.collection('products').doc(product_id).collection('feeds').doc(feed_id).collection('items').doc(item_id).update(
            {
                position:newPosition,
            }
        )
    }
    return responce;
}

export const getFeedItems = async (product_id, feed_id) => {
    let responce = {
        success:true,
        data:[]
    }
    return await db.collection('products').doc(product_id).collection('feeds').doc(feed_id).collection('items').get().then(snapshot => {
        let feeditems = [];
        if(!snapshot.empty)
        {
            snapshot.forEach(doc => {
                let item = doc.data();
                item.id = doc.id;
                feeditems.push(item);
            });
            responce.data = feeditems;
        }
        return responce;
    }).catch((error)=>{
        responce.success = false;
        responce.error = error;
        delete responce.data;
        return responce;
    });
}
export const getFeedGroupItems = async (product_id, feed_id, group_id) => {
    let responce = {
        success:true,
        data:[]
    }
    await db.collection('products').doc(product_id).collection('feeds').doc(feed_id).collection('items').get().then(snapshot => {
        let feeditems = [];
        if(!snapshot.empty)
        {
            snapshot.forEach(doc => {
                let item = doc.data();
                item.id = doc.id;
                feeditems.push(item)
            });
            responce.data = feeditems.filter((item)=>{
                return item.group_id === group_id;
            });
        }
        return
    }).catch((error)=>{
        responce.success = false;
        responce.error = error;
        delete responce.data;
        return 
    });
    return responce;
}
export const deleteFeedItem = async (product_id, feed_id, item_id) => {
    let responce = {
        success:true
    }
    await db.collection('products').doc(product_id).collection('feeds').doc(feed_id).collection('items').doc(item_id).delete().catch((error)=>{
        responce.success = false;
        responce.error = error;
        return 
    });
    return responce;
}
export const updateFeedItem = async (product_id, feed_id, item_id, form) => {
    let responce = {
        success:true,
        data:[]
    }
    await db.collection('products').doc(product_id).collection('feeds').doc(feed_id).collection('items').doc(item_id).update(
        {
            name:form.name,
            slug:form.slug,
            placeholder:(form.placeholder)?form.placeholder:null,
            muted:(form.muted)?form.muted:null,
            group_id:(form.group)?form.group:null
        }
    ).then(ref => {
        responce.data = ref
    }).catch((error)=>{
        responce.success = false;
        responce.error = error;
        delete responce.data;
        return 
    });
    return responce;
}
export const updateFeedItems = async (product_id, feed_id, form) => {
    let responce = {
        success:true
    }
    let keys = [];
    for(var key in form){
        keys.push(key);
    }
    /// updating the items
    for(let i = 0; keys.length > i; i++){
        let value = form[keys[i]];
        db.collection('products').doc(product_id).collection('feeds').doc(feed_id).collection('items').doc(keys[i]).update({
            value:value
        })
    }
    return responce;
}