import React, {Component, Fragment} from 'react';
import {createContainerItem, getContainerGroups} from '../../../data/containers';
import {Store} from '../../../stores/Store';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faTrash
} from '@fortawesome/free-solid-svg-icons';
import {getFeeds} from '../../../data/feeds'
class NewContainerItem extends Component {
    state = {
        form:{
            name:'',
            slug:'',
            type:'text',
            group:this.props.group_id,
            select:[],
            linked:null
        },
        addSelect:{
            value:'',
            title:''
        },
        feeds:null,
        groups:null
    }
    componentDidMount(){
        getContainerGroups(this.context.product_id, this.props.container_id,).then((res)=>{
            this.setState({
                groups:res.data
            });
        });
        getFeeds(this.context.product_id).then((res)=>{
            this.setState({
                feeds:res.data
            });
        });
    }
    addSelect = (e) => {
        let addSelect = this.state.addSelect;
        let form = this.state.form
        form.select.push(addSelect);
        this.setState({
          form:form,
          addSelect:{
            value:'',
            title:''
        }
        });
    }
    deleteSelect = (index) => {
        let form = this.state.form
        let filteredItems = form.select.filter((item, key) => key !== index);
        form.select = filteredItems;
        this.setState({
          form:form
        });
    }
    handleSelect = (e) => {
        let addSelect = {...this.state.addSelect}
        addSelect[e.target.name] = e.target.value;
        this.setState({
            addSelect:addSelect
        });
    }
    handleChange = (e) => {
        let form = {...this.state.form}
        if(e.target.name === 'slug'){
            form[e.target.name] = e.target.value.replace(/[^a-zA-Z0-9_-]/g,'');
        }else{
            form[e.target.name] = e.target.value;
        }
        this.setState({
          form:form
        });
    }
    create = async() =>{
        let error = false;
        error = (this.state.form.name.length > 3 && error === false)?true:(error !== false)?error:'Name needs to be over 3 charactors';
        error = (this.state.form.name.length < 24 && error === false)?true:(error !== false)?error:'Name needs to be less than 24 charactors';
        error = (this.state.form.slug.length > 3 && error === false)?true:(error !== false)?error:'Slug needs to be over 3 charactors';
        error = (this.state.form.slug.length < 24 && error === false)?true:(error !== false)?error:'Slug needs to be less than 24 charactors';
        if(error)
        {
            createContainerItem(this.context.product_id, this.props.container_id, this.state.form).then(()=>{
                this.props.update();
                this.props.close();
            });
        }
    }
    render(){
        return (
            <Fragment>
                <div className="input-group mb-3">
                    <div className="input-group-prepend">
                        <span className="input-group-text">Screen Name</span>
                    </div>
                    <input type="text" name='name' required={true} onChange={this.handleChange} value={this.state.form.screen_name} className={(this.state.error && this.state.form.name.length < 3)?"form-control formerror":"form-control"} placeholder="Item screen_name" />
                </div>
                <div className="input-group mb-3">
                    <div className="input-group-prepend">
                        <span className="input-group-text">Slug</span>
                    </div>
                    <input type="text" name='slug' required={true} onChange={this.handleChange} value={this.state.form.slug} className={(this.state.error && this.state.form.name.length < 3)?"form-control formerror":"form-control"} placeholder="Slug (A-Za-z0-9 - _)" />
                </div>
                <div className="input-group mb-3">
                    <div className="input-group-prepend">
                        <span className="input-group-text">Place holder</span>
                    </div>
                    <input type="text" name='placeholder' required={true} onChange={this.handleChange} value={this.state.form.placeholder} className={(this.state.error && this.state.form.name.length < 3)?"form-control formerror":"form-control"} placeholder="Item Name" />
                </div>
                <div className="input-group mb-3">
                    <div className="input-group-prepend">
                        <span className="input-group-text">Sub Text</span>
                    </div>
                    <input type="text" name='muted' required={true} onChange={this.handleChange} value={this.state.form.muted} className={(this.state.error && this.state.form.name.length < 3)?"form-control formerror":"form-control"} placeholder="Item Name" />
                </div>
                <div className="input-group mb-3">
                    <div className="input-group-prepend">
                        <span className="input-group-text">Item Type</span>
                    </div>
                    <select className='form-control' name='type' onChange={this.handleChange} defaultValue='text'>
                        <option value='text'>Text</option>
                        <option value='wysiwyg'>WYSIWYG</option>
                        <option value='text_area'>Text Area</option>
                        <option value='image'>Image</option>
                        <option value='audio'>Audio</option>
                        <option value='video'>Video</option>
                        <option value='bool'>Bool</option>
                        <option value='number'>Number</option>
                        <option value='pdf'>PDF</option>
                        <option value='csv'>CSV</option>
                        <option value='select'>Select</option>
                        <option value='feeditem'>Feed Item</option>
                        <option value='feedmultiitem'>Multiple Feed Items</option>
                    </select>
                </div>
                {(this.state.form.type === 'select')&&(
                    <Fragment>
                    <div className="input-group mb-3">
                        <div className="input-group-prepend">
                            <span className="input-group-text">Add Select Item</span>
                        </div>
                        <input type="text" name='title' required={true} onChange={this.handleSelect} value={this.state.addSelect.title} className={"form-control"} placeholder="Select Item Name" />
                        <input type="text" name='value' required={true} onChange={this.handleSelect} value={this.state.addSelect.value} className={"form-control"} placeholder="Select Item Value" />
                        <div className="input-group-append">
                            <button onClick={this.addSelect} className="input-group-text">Add</button>
                        </div>
                    </div>
                    {
                        (this.state.form.select.length > 0)&&(
                            <table>
                                <thead>
                                    <tr>
                                        <td>
                                            title
                                        </td>
                                        <td>
                                            value
                                        </td>
                                        <td>
                                            delete
                                        </td>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.form.select.map((item, key)=>{
                                        return (
                                            <tr key={key}>
                                                <td>{item.title}</td>
                                                <td>{item.value}</td>
                                                <td style={{width:'10%'}}><span onClick={()=>{this.deleteSelect(key)}}><FontAwesomeIcon icon={faTrash} /></span></td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                        )
                    }
                    </Fragment>
                )}
                {(this.state.form.type === 'feeditem' || this.state.form.type === 'feedmultiitem')&&<select className='form-control' name='linked' onChange={this.handleChange}>
                        <option value=''>Select</option>
                        {this.state.feeds.map((item, i)=>{
                            return <option key={i} value={item.id}>{item.name}</option>
                        })}
                    </select>}
                    {(this.state.form.type === 'feedmultiitem')&&<select className='form-control' name='linked' onChange={this.handleChange}>
                        <option value=''>Select</option>
                        {this.state.feeds.map((item, i)=>{
                            return <option key={i} value={item.id}>{item.name}</option>
                        })}
                    </select>}
                {(this.state.group_id === null)&&(
                    <div className="input-group mb-3">
                        <div className="input-group-prepend">
                            <span className="input-group-text" id="namelabel">Belongs to:</span>
                        </div>
                        <select className='form-control' name='group' onChange={this.handleChange} defaultValue='text'>
                            <option value=''>Select</option>
                            {(this.state.groups)&&this.state.groups.map((group, i)=>{
                                return (<option key={i} value={group.id}>{group.name}</option>)
                            })}
                        </select>
                    </div>
                )}
                <button className="btn btn-success nomargin floatright" onClick={this.create}>Create</button>
            </Fragment>
        )
    }
}
NewContainerItem.contextType = Store; // This part is important to access context values
export default NewContainerItem;