import  {db} from '../firebase';

export const cloneContainerGroup = async (product_id, container_id, form) => {
    let responce = {
        success:true
    }
    let data = false;
    let new_id = false;
    let size = 0;
    await db.collection('products').doc(product_id).collection('containers').doc(container_id).collection('groups').get().then(snap => {
        size = snap.size
    });
    await db.collection('products').doc(product_id).collection('containers').doc(container_id).collection('groups').doc(form.clone_id).get().then(doc => {
        data = doc.data();
        data.name = form.name;
        data.slug = form.slug;
        data.position = size;
        return
    }).catch((error)=>{
        responce.success = false;
        responce.error = error;
        return 
    });
    if(responce.success === true && data)
    {
        await db.collection('products').doc(product_id).collection('containers').doc(container_id).collection('groups').add(data).then((res)=>{
            new_id = res.id;
        }).catch((error)=>{
            responce.success = false;
            responce.error = error;
            return 
        });

        await db.collection('products').doc(product_id).collection('containers').doc(container_id).collection('items').where('group_id', '==', form.clone_id).get().then((snapshot)=>{
            snapshot.forEach(async (doc) => {
                let item = doc.data();
                item.group_id = new_id;
                
                await db.collection('products').doc(product_id).collection('containers').doc(container_id).collection('items').add(item)
            });
        }).catch((error)=>{
            responce.success = false;
            responce.error = error;
            return 
        });
    }
    return responce;
}
export const cloneFeedGroup = async (product_id, feed_id, form) => {
    let responce = {
        success:true
    }
    let data = false;
    let new_id = false;
    let size = 0;
    await db.collection('products').doc(product_id).collection('feeds').doc(feed_id).collection('groups').get().then(snap => {
        size = snap.size
    });
    await db.collection('products').doc(product_id).collection('feeds').doc(feed_id).collection('groups').doc(form.clone_id).get().then(doc => {
        data = doc.data();
        data.name = form.name;
        data.slug = form.slug;
        data.position = size;
        return
    }).catch((error)=>{
        responce.success = false;
        responce.error = error;
        return 
    });
    if(responce.success === true && data)
    {
        await db.collection('products').doc(product_id).collection('feeds').doc(feed_id).collection('groups').add(data).then((res)=>{
            new_id = res.id;
        }).catch((error)=>{
            responce.success = false;
            responce.error = error;
            return 
        });

        await db.collection('products').doc(product_id).collection('feeds').doc(feed_id).collection('items').where('group_id', '==', form.clone_id).get().then((snapshot)=>{
            snapshot.forEach(async (doc) => {
                let item = doc.data();
                item.group_id = new_id;
                
                await db.collection('products').doc(product_id).collection('feeds').doc(feed_id).collection('items').add(item)
            });
        }).catch((error)=>{
            responce.success = false;
            responce.error = error;
            return 
        });
    }
    return responce;
}