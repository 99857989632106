import React, {Component, Fragment} from 'react';
//componants
import {Store} from "../../stores/Store";
import Table from "./Table";
import Stats from "./Stats";
import {getUsers, deleteUser, disableUser, enableUser} from '../../data/users';
import PageTop from "../Global/pagetop";
import UserButtons from './UserButtons';
import Loading from '../Global/Loading';
class Users extends Component {
    state = {
      activeTab:1,
      users:[],
      loading:true,
      active:null,
      newusers:null
    }
    componentDidMount(){
      this.update();
    }
    switch = (tab) => {
      this.setState({
        activeTab: (this.state.activeTab !== tab)?tab:this.state.activeTab
      });
    }
    update = () => {
      getUsers(this.context).then(async (res)=>{
        await this.setState({
          users:(res)?(res.data)?res.data:null:null,
          loading:false
        })
      });
    }
    deleteUser = (user) => {
      if(window.confirm('Are you sure you would like to delete '+user.displayName))
      {
        deleteUser(this.context, user.id).then((res)=>{
          this.update();
        });
      }
    }
    disableUser = (user) =>{
      if(window.confirm('Are you sure you would like disable '+user.displayName))
      {
        disableUser(this.context, user.uid).then((res)=>{
          alert('User Disabled');
          this.update();
        });
      }
    }
    enableUser = (user) =>{
      if(window.confirm('Are you sure you would like enable '+user.displayName))
      {
        enableUser(this.context, user.uid).then((res)=>{
          alert('User Disabled');
          this.update();
        });
      }
    }
    render() {
      return (
        <Fragment>
          <PageTop name='Users' buttons={<UserButtons callback={this.update} toggle={this.switch} active={this.state.activeTab} />}/>
          <div className='inner'>
                {(this.state.activeTab === 1)&&<div className='row'>
                    <div className='col-12'>
                        {(!this.state.loading)?<Table
                          data={this.state.users}
                          context={this.context}
                          delete={this.deleteUser}
                          disableUser={this.disableUser}
                          enableUser={this.enableUser}
                          update={this.update}
                        />:<Loading />}
                    </div>
                </div>}
                {(this.state.activeTab === 2)&&<div className='row'>
                  <div className='col-12'>
                    <Stats />
                  </div>
                </div>}
          </div>
        </Fragment>
      )
    }
}
Users.contextType = Store; // This part is important to access context values
export default Users;
