import React, {Fragment, useContext, useEffect, useState} from 'react';
import {getFeedContainers} from '../../../../data/containers';
import {Store} from '../../../../stores/Store';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faTrash
} from '@fortawesome/free-solid-svg-icons';

const FeedMultiItem = (props) => {
    const [feedItems, setFeedItems] = useState(null);
    const context = useContext(Store);
    let values = (props.item.value)?JSON.parse(props.item.value):[];
    const [selected, setSelected] = useState((values && values.items && values.items.length > 0)?values.items:[]);

    useEffect(()=>{
        if(props.item.linked){
            getFeedContainers(context.product_id, props.item.linked).then((responce)=>{
                setFeedItems(responce.data.filter(item=>item.template === false));
            });
        }
    }, []);
    const addSelect = (e) => {
        setSelected([...selected, e.target.value])
    }
    useEffect(()=>{
        let obj = {linked:props.item.linked, items:selected};
        props.handleMultiSelectChange(props.item.id, obj)
    }, [selected])
    const removeSelect = (key) => {
        let newarray = selected.filter((item, i)=>{
            return i !== key;
        });
        setSelected(newarray);
    }
    return (feedItems)
    ?<Fragment>
        <div className="input-group mb-3">
            <div className="input-group-prepend">
                <div className="input-group-text">{props.item.name}</div>
            </div>
            <select 
                name={props.item.id} 
                required 
                className='form-control'
                onChange={(e)=>{
                    addSelect(e);
                    }}
            >
                <option value=''>not selected</option>
                {feedItems.map((item, i)=>{
                    return(
                        <option key={i} value={item.id}>
                            {item.name}
                        </option>
                    )
                })}
            </select>
            
        </div>
        {(selected.length > 0)&&<div>
            <table>
                <tr>
                    <th>item</th>
                    <th>remove</th>
                </tr>
                {
                    selected.map((arrayitem, i) => {
                        return (
                            <tr>
                                <td>{feedItems.find((item, i)=>{ return item.id === arrayitem}).name}</td>
                                <td><span className={'click'} onClick={()=>{removeSelect(i)}}><FontAwesomeIcon icon={faTrash} /></span></td>
                            </tr>
                        );
                    })
                }
            </table>
        </div>
        }
        </Fragment>
        :'No Feed Linked'
}
export default FeedMultiItem;