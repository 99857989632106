import React, {Fragment, Component} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faTrash, faEye, faEdit, faPowerOff
} from '@fortawesome/free-solid-svg-icons';
import {NavLink} from 'react-router-dom';
import {deleteFeed, toggleFeed } from "../../../data/feeds";
import Processing from '../../Global/Processing';
import {Store} from '../../../stores/Store';
import ModalAlert from '../../Global/ModalAlert';
import EditFeed from '../EditFeed';

class ButtonBar extends Component{
    state = {
        processing:false
    }
    toggleActive = async () => {
        await this.setState({
            processing:true
        })
        await toggleFeed(this.context.product_id, this.props.data.id, this.props.data.active); 
        await this.props.update(); 
        await this.setState({
            processing:false
        });
    }
    deleteFeed= async() => {
        await this.setState({
            processing:true
        })
        await deleteFeed(this.context.product_id, this.props.data.id);
        await this.setState({
            processing:false
        })
        await this.props.update(); 
    }
    render(){
        return (!this.state.processing)?(
            <div className="card-footer d-flex bg-gradient">
                <Fragment>
                    <div>
                        <ModalAlert 
                            buttonClassName='btn btn-sm barbutton floatright' 
                            buttonType='none'
                            buttonIcon={<Fragment><FontAwesomeIcon icon={faEdit} /></Fragment>} 
                            title={'Edit'} 
                            content={<EditFeed data={this.props.data} update={this.props.update} />}
                            size='lg'
                        />
                        <NavLink className="btn btn-sm barbutton floatright" to={"/product/"+this.context.product_id+'/feeds/'+this.props.data.id}>
                            <FontAwesomeIcon icon={faEye} />
                        </NavLink>
                    </div>
                    <div>
                        <button onClick={this.deleteFeed} className="btn btn-sm barbutton floatright warning"><FontAwesomeIcon icon={faTrash} /></button>
                        <button onClick={this.toggleActive}  className="btn btn-sm barbutton floatright warning"><FontAwesomeIcon icon={faPowerOff} /></button>
                    </div>
                </Fragment>
            </div>        
        ):<Processing />
    }  
}
ButtonBar.contextType = Store; // This part is important to access context values
export default ButtonBar;