import React, {Fragment, Component} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faCubes, faCube, faObjectUngroup
} from '@fortawesome/free-solid-svg-icons';
import Processing from '../../Global/Processing';
import {Store} from "../../../stores/Store";
import ButtonBar from './ButtonBar';

class FeedCard extends Component{
    state = {
        removing:false
    }
    render(){
        return (
            <li className="col-12 col-md-6 col-lg-4 card_object">
                <div className={"card "+((!this.props.data.active)&&'inactive')}>
                    <div className="card-header card-header-borderless d-flex justify-content-between">
                    <div><FontAwesomeIcon icon={faCubes} /> {this.props.data.name}</div>
                    <div>
                        {(this.props.data.active)?<div className='dot click' title='Active'>Active</div>:<div className='dot warning click' title='Inacive'>Inactive</div>}
                    </div>
                    </div>
                    <div className="card-body">
                        <div className="media">
                            <div className="media-body siteCard">
                                {(this.state.removing)&&(
                                    <Processing />
                                )}
                            </div>
                        </div>
                    </div>
                    {(!this.state.removing)
                        &&<ButtonBar
                            update={this.props.update}
                            data={this.props.data}
                        />}
                </div>
            </li>
        )
    }
}
FeedCard.contextType = Store; // This part is important to access context values
export default FeedCard;