import React, {Fragment} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faTimes, faEdit,faChevronUp, faChevronDown
} from '@fortawesome/free-solid-svg-icons';
import ModalAlert from '../../Global/ModalAlert';
import UpdateContainerItem from './UpdateContainerItem';
import FeedItem from './types/FeedItem';
import FeedMultiItem from './types/FeedMultiItem';
import Text from './types/Text';
import Wysiwyg from './types/Wysiwyg';
import Bool from './types/Bool';
import TextArea from './types/TextArea';
import Image from './types/Image';
import Audio from './types/Audio';
import Video from './types/Video';
import Pdf from './types/Pdf';
import Csv from './types/Csv';
import Number from './types/Number';
import Select from './types/Select';
const Inputs = (props) => {
    return (
        <div className='item-card'>
            <div className='card-controls'>
                {(props.item.muted)&&<small className="text-muted floatleft">{props.item.muted}</small>}
                <span 
                    className='click floatright btn boxbutton smaller'
                    onClick={()=>{props.delete(props.item.id)}} 
                    title='Delete'
                >
                    <FontAwesomeIcon icon={faTimes} />
                </span>
                {(props.item.position !== 0)&&<span onClick={()=>{ props.reorder(props.item.id, props.item.group_id, (props.item.position-1))}} className="click floatright btn boxbutton smaller"><FontAwesomeIcon icon={faChevronUp} /></span>}
                {(props.last !== true)&&<span onClick={()=>{props.reorder(props.item.id, props.item.group_id, (props.item.position+1))}} className="click floatright btn boxbutton smaller"><FontAwesomeIcon icon={faChevronDown} /></span>}
                <ModalAlert 
                    buttonClassName='click floatright btn boxbutton smaller'
                    span={true}
                    buttonIcon={<Fragment><FontAwesomeIcon icon={faEdit} /></Fragment>} 
                    title={'Edit'} 
                    content={<UpdateContainerItem data={props.item} container_id={props.container_id} update={props.update} />}
                    size='lg'
                />
            </div>
            {(props.item.type === 'text')&&<Text {...props} />}
            {(props.item.type === 'wysiwyg')&&<Wysiwyg {...props} />}
            {(props.item.type === 'bool')&&<Bool {...props} />}
            {(props.item.type === 'text_area')&&<TextArea {...props} />}
            {(props.item.type === 'image')&&<Image {...props} />}
            {(props.item.type === 'audio')&&<Audio {...props} />}
            {(props.item.type === 'video')&&<Video {...props} />}
            {(props.item.type === 'pdf')&&<Pdf {...props} />}
            {(props.item.type === 'csv')&&<Csv {...props} />}
            {(props.item.type === 'number')&&<Number {...props} />}
            {(props.item.type === 'select')&&<Select {...props} />}
            {(props.item.type === 'feeditem')&&<FeedItem {...props} />}
            {(props.item.type === 'feedmultiitem')&&<FeedMultiItem {...props} />}
        </div>
    )
}
export default Inputs;