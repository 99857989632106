import React from 'react';

const TextArea = (props) => {
    return (
        <div className="input-group mb-3">
            <div className="input-group-prepend">
                <div className="input-group-text">{props.item.name}</div>
            </div>
            <textarea 
                name={props.item.id} 
                className='form-control' 
                placeholder={(props.item.placeholder)?props.item.placeholder:null}
                onChange={(e)=>{props.handleChange(e, 'text_area')}} 
                value={(props.currentValue !== null && props.currentValue !== undefined)?props.currentValue:(props.item.value)?props.item.value:''}
            >
            </textarea>
        </div>
    )
}
export default TextArea;