import React, {Fragment, Component} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faTrash, faEdit
} from '@fortawesome/free-solid-svg-icons';
import {deleteContainerBucket} from "../../../data/containers";
import Processing from '../../Global/Processing';
import {Store} from '../../../stores/Store';
import ModalAlert from '../../Global/ModalAlert';
import EditContainerBucket from './EditContainerBucket';

class ButtonBar extends Component{
    state = {
        processing:false
    }
    delete = async() => {
        await this.setState({
            processing:true
        })
        await deleteContainerBucket(this.context.product_id, this.props.data.id);
        await this.setState({
            processing:false
        })
        await this.props.update(); 
    }
    render(){
        return (!this.state.processing)?(
            <div className="card-footer d-flex bg-gradient">
                <Fragment>
                    <div>
                        <ModalAlert 
                            buttonClassName='btn btn-sm barbutton floatright' 
                            buttonType='none'
                            buttonIcon={<Fragment><FontAwesomeIcon icon={faEdit} /></Fragment>} 
                            title={'Edit'} 
                            content={<EditContainerBucket data={this.props.data} update={this.props.update} />}
                            size='lg'
                        />
                    </div>
                    <div>
                        <button onClick={this.delete} className="btn btn-sm barbutton floatright warning"><FontAwesomeIcon icon={faTrash} /></button>
                    </div>
                </Fragment>
            </div>        
        ):<Processing />
    }  
}
ButtonBar.contextType = Store; // This part is important to access context values
export default ButtonBar;