import React, {Fragment} from 'react';
//componants
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faPlus, faCloud, faSpinner
} from '@fortawesome/free-solid-svg-icons';
import ModalAlert from '../../Global/ModalAlert';
import NewContainerGroup from './NewContainerGroup';
const ContainerViewButtons = (props) => {
    return (
        <Fragment>
            <button 
                onClick={props.save} 
                className={'topboxbutton floatright btn btn-none btn-sm active'} 
                title='Save Container'
            >
                {(props.saving === false)?<FontAwesomeIcon icon={faCloud} />:<FontAwesomeIcon icon={faSpinner} spin />} Save
            </button>
            <ModalAlert 
                buttonClassName='topboxbutton floatright' 
                buttonType='none'
                buttonIcon={<Fragment><FontAwesomeIcon icon={faPlus} /> <span>Group</span></Fragment>} 
                title={'New Page'} 
                content={<NewContainerGroup update={props.update} container_id={props.container_id} />}
                size='lg'
            />
        </Fragment>
    );
}
export default ContainerViewButtons;
