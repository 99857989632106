import React from 'react';
// Import React Table
import ReactTable from "react-table";
import "react-table/react-table.css";
import ModalAlert from '../Global/ModalAlert';
import AccountDetails from './AccountDetails';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faEye
} from '@fortawesome/free-solid-svg-icons';
import {DisableButton, DeleteButton} from './buttons';
const Table = (props) =>{
    return <ReactTable
               data={props.data}
               columns={[
                  {
                     Header: "Users",
                     headerClassName: 'group_by',
                     columns:[
                        {
                           Header: "Name",
                           accessor: "displayName",
                        Cell: row => (<span className='tableCell'><span>{row.value}</span></span>)
                        },
                        {
                           Header: "Email",
                           accessor: "email",
                        Cell: row => (<span className='tableCell'><span>{row.value}</span></span>)
                        },
                        {
                           Header: "Last Active",
                           accessor: "lastSignInTime",
                        Cell: row => (<span className='tableCell'><span>{(row.value !== null)?moment(row.value).fromNow():'Not Yet Logged In'}</span></span>)
                        },
                        {
                            Header: "Actions",
                            accessor: "uid",
                            Cell: row => (
                                 <span className='tableCell'>
                                    <span className='centerCell'>
                                       <ModalAlert 
                                          buttonClassName='boxbutton btn btn-sm'
                                          buttonStyle={{margin:'0px 5px'}}
                                          buttonType='none'
                                          buttonIcon={<FontAwesomeIcon icon={faEye} />} 
                                          title={'View User'} 
                                          content={<AccountDetails uid={row.value} update={props.update} />}
                                          callback={props.callback}
                                          size='lg'
                                       />
                                       <DisableButton enableUser={props.enableUser} disableUser={props.disableUser} user={row.original} />
                                       <DeleteButton delete={props.delete} user={row.original} />
                                    </span>
                                 </span>
                              )
                        }
                     ]
                   }
               ]}
               defaultPageSize={10}
               className="spacetop -striped -highlight"
             />;
}
export default Table;
