import React, {Component, Fragment} from 'react';
//componants
import {Store} from "../../stores/Store";
import {updatePassword, updateUser, deleteMyAccount, Logout} from "../../data/users";
import ModalAlert from '../Global/ModalAlert';
import DeleteAccount from './DeleteAccount';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faTrash
} from '@fortawesome/free-solid-svg-icons';

class AccountDetails extends Component {
    state = {
      form:{
          f_name:this.props.user.f_name,
          l_name:this.props.user.l_name,
          email:this.props.user.email,
          current_password:'',
          new_password:'',
          confirm_password:'',
      },
      updated:false,
      passworderror:false,
      newpassworderror:false,
      usererror:false,
      changePassword:false
    }
    toggleChangePassword = () => {
      let form = {...this.state.form}
      form['current_password'] = '';
      form['new_password'] = ''
      form['confirm_password'] = '';
      this.setState({
        changePassword:!this.state.changePassword,
        form:form
      })
    }
    handleChange = (e) => {
        let form = {...this.state.form}
        form[e.target.name] = e.target.value;
        this.setState({
          form:form,
          passworderror:false,
          newpassworderror:false,
        });
    }
    update = () => {
      let userform = {
        f_name:this.state.form.f_name,
        l_name:this.state.form.l_name,
        email:this.state.form.email,
        password:this.state.form.current_password
      }
      if(this.state.changePassword === true)
      {
        if(this.state.form.new_password === this.state.form.confirm_password && this.state.form.new_password.length > 3)
        {
          let passwordform = {
            password:this.state.form.current_password,
            new_password:this.state.form.new_password,
            confirm_password:this.state.form.confirm_password
          }
          updatePassword(passwordform).then(async (res) => {
            if(res.success === true){
              updateUser(userform).then(async (res)=>{
                await this.alertMod('updated');
                if(res.email === true)
                {
                  Logout(this.context);
                }
              }).catch((error)=>{
                this.alertMod('usererror')
              });
            }else{
              this.alertMod('passworderror');
            }
          })
        }else{
          this.alertMod('newpassworderror');
        }
      }else{
        if(this.state.form.current_password)
        {
          updateUser(userform).then(async (res)=>{
            await this.alertMod('updated');
            if(res.email === true)
                {
                  Logout(this.context);
                }
          }).catch((error)=>{
            this.alertMod('newpassworderror')
          });
        }else{
          this.alertMod('passworderror')
        }
      }
    }
    alertMod = async (type) => {
      await this.setState(
        {
          [type]:true
        }
      )
      setTimeout(() => { 
        this.setState(
          {
            passworderror:false
          }
        );
       }, 3000);
    }
    deleteAccount = (close) => {
      deleteMyAccount(this.context.token).then((res) => {
        if(res.success === false)
        {
          close();
          alert('There has been a problem deleting your account - please try again later');
        }else{
          Logout();
        }
      });
    }
    render() {
      return (
      <div className="card spaceBottom">
        <div className="card-header">
          My Account
          <ModalAlert 
              buttonClassName='boxbutton btn-danger btn btn-sm floatright'
              buttonStyle={{margin:'0px 5px'}}
              buttonType='none'
              buttonIcon={<FontAwesomeIcon icon={faTrash} />} 
              title={'Delete Your Account'} 
              content={<DeleteAccount />}
              callback={this.deleteAccount}
              size='lg'
            />
        </div>
          <div className="card-body">
            {(this.state.updated)&&(<div className="alert alert-success" role="alert">User Updated!</div>)}
            {(this.state.usererror)&&(<div className="alert alert-danger" role="alert">User Update Error!</div>)}
                <div className="input-group mb-3">
                      <div className="input-group-prepend">
                        <span className="input-group-text" id="namelabel">First Name</span>
                      </div>
                      <input type="text" name='f_name' onChange={this.handleChange} value={this.state.form.f_name} className="form-control" placeholder="First Name" />
                </div>
                <div className="input-group mb-3">
                      <div className="input-group-prepend">
                        <span className="input-group-text" id="namelabel">Second Name</span>
                      </div>
                      <input type="text" name='l_name' onChange={this.handleChange} value={this.state.form.l_name} className="form-control" placeholder="Second Name" />
                </div>
                <div className="input-group mb-3">
                    <div className="input-group-prepend">
                      <span className="input-group-text" id="namelabel">Email</span>
                    </div>
                    <input type="text" name='email' onChange={this.handleChange} value={this.state.form.email} autoComplete="off" className="form-control" placeholder="Email" />
                </div>
                {(!this.props.super)&&(
                  <div className="input-group mb-3">
                    <div className={(this.state.passworderror)?"input-group-prepend error":"input-group-prepend"}>
                      <span className="input-group-text">Current Password</span>
                    </div>
                    <input type="password" name='current_password' onChange={this.handleChange} className="form-control" value={this.state.form.current_password} autoComplete={0} placeholder="Enter your current password to make changes" />
                  </div>
                )}

                <div className='space bottom'>
                  {(this.state.changePassword)?(
                    <span className='btn btn-outline-secondary' onClick={this.toggleChangePassword}>Don't Change Password</span>
                  ):(
                    <span className='btn btn-outline-secondary' onClick={this.toggleChangePassword}>Change Password</span>
                  )}
                  <button className="btn btn-success nomargin floatright" onClick={this.update}>Update Account Details</button>
                </div>
                {(this.state.changePassword)&&(
                  <Fragment>
                    {(!this.props.super)&&(
                      <div className="input-group mb-3">
                        <div className={(this.state.passworderror)?"input-group-prepend error":"input-group-prepend"}>
                          <span className="input-group-text">Current Password</span>
                        </div>
                        <input type="password" name='current_password' onChange={this.handleChange} className="form-control" value={this.state.form.current_password} autoComplete={0} placeholder="Enter your current password to make changes" />
                      </div>
                    )}
                    <div className="input-group mb-3">
                        <div className={(this.state.newpassworderror)?"input-group-prepend error":"input-group-prepend"}>
                          <span className="input-group-text">New Password</span>
                        </div>
                        <input type="password" name='new_password' onChange={this.handleChange} className="form-control"  value={this.state.form.new_password} autoComplete={0} placeholder="Enter your new password" />
                    </div>
                    <div className="input-group mb-3">
                        <div className={(this.state.newpassworderror)?"input-group-prepend error":"input-group-prepend"}>
                          <span className="input-group-text">Repeat New Password</span>
                        </div>
                        <input type="password" name='confirm_password' onChange={this.handleChange} className="form-control"  value={this.state.form.confirm_password} autoComplete="off" autofill='off' placeholder="Enter your new password again" />
                    </div>
                  </Fragment>
                )}
                
                </div>
        </div>
      );
    }
}
AccountDetails.contextType = Store; // This part is important to access context values
export default AccountDetails;
