import React, {Component} from 'react';
import {Route, Switch} from 'react-router-dom';
/// Componants
import Sidebar from "../../components/Global/Sidebar";
import TopNav from '../../components/Global/TopNav';
import Dashboard from '../../components/Dashboard/';
import Account from '../../components/Account';
import Users from '../../components/Users';
import Product from '../../components/Product';
import ViewContainers from '../../components/Containers/ViewContainers';
import DataContainers from '../../components/Containers/DataContainers';
import FeedContainers from '../../components/Feeds/FeedContainers';
import ContainerView from '../../components/Containers/ContainerView';
import Feeds from '../../components/Feeds';
import Content from '../../components/Content';
import {Store} from "../../stores/Store";
import {Logout} from "../../data/users";
import NoMatch from '../../components/NoMatch';
import {auth} from '../../firebase';
import Loading from '../../components/Global/Loading';

class LoggedIn extends Component{
    state = {
        auth:false
    }
    componentDidMount(){
        auth.onAuthStateChanged((user) => {
            if (user) {
                this.setState({auth:true})
            } else {
                this.setState({auth:false})
                Logout(this.context);
            }
        });
    }
    render(){
        return (this.state.auth)?(
            <div className="container-fluid">
                <div className="row">
                    <Sidebar />
                    <main role="main" className="col-11">
                        <TopNav logout={() => {
                            Logout(this.context);
                        }}/>
                        <Switch>
                            <Route path='/product/:id/content' exact component={Content} />
                            <Route path='/product/:id/feeds' exact component={Feeds} />
                            <Route path='/product/:id/feeds/:feed_id' exact component={FeedContainers} />
                            <Route path='/product/:id/feeds/:feed_id/:cat_id?' exact component={FeedContainers} />
                            <Route path='/product/:id/containers/:type/:container_id' exact component={ContainerView} />
                            <Route path='/product/:id/viewcontainers' exact component={ViewContainers} />
                            <Route path='/product/:id/datacontainers' exact component={DataContainers} />
                            <Route path='/product/:id' exact component={Product} />
                            <Route path='/account' exact component={Account} />
                            <Route path='/users' exact component={Users} />
                            <Route path='/' exact component={Dashboard} />
                            <Route path='*' component={NoMatch} />
                        </Switch>
                    </main>
                </div>
            </div>
        ):(
            <div className="container-fluid">
                <div className="row" style={{minHeight:'100vh', padding:'40vh'}}>
                    <main role="main" className="col-12">
                        <Loading />
                    </main>
                </div>
            </div>
        );
    }
}
LoggedIn.contextType = Store; // This part is important to access context values
export default LoggedIn;

