import React, {useContext} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {NavLink} from 'react-router-dom';
import {exthook} from '../../data/external';
import {
    faUser,
    faSignOutAlt,
    faUsers,
    faSitemap,
    faBullseye
} from '@fortawesome/free-solid-svg-icons';
import {Store} from "../../stores/Store";

const TopNav = (props) => {
        const context = useContext(Store); // This part is important to access context values
        return(
            <div className='row topnav'>
                <div className='col-4'>
                    <span className='welcome'>{process.env.REACT_APP_NAME}</span>
                </div>
                <div className='col-8 textright'>
                
                {(context.access === 'super' && context.hook)&&<span onClick={()=>{exthook(context.hook)}} title='Hook' className='btn btn-clear'><FontAwesomeIcon icon={ faBullseye  } /></span>}
                    <NavLink to="/" title='Products' className='btn btn-clear'><FontAwesomeIcon icon={ faSitemap  } /></NavLink>
                    {(context.access === 'super')&&<NavLink to="/users" title='Users' className='btn btn-clear'><FontAwesomeIcon icon={ faUsers  } /></NavLink>}
                    <NavLink to="/account" className='btn btn-clear' title='My Account'><FontAwesomeIcon icon={ faUser  } /></NavLink>
                    <NavLink to="/" onClick={props.logout} className='btn btn-clear' title='Sign Out'><FontAwesomeIcon icon={ faSignOutAlt  } /></NavLink>
                </div>
            </div>
        );

}
export default TopNav;
