import React, {Component, Fragment} from 'react';
import {createProduct} from '../../data/products';
class NewProduct extends Component {
    state = {
        form:{
            name:'',
            hook:'',
            type:'web'
        }
    }
    handleChange = (e) => {
        let form = {...this.state.form}
        form[e.target.name] = e.target.value;
        this.setState({
          form:form
        });
    }
    create = async() =>{
        let error = false;
        error = (this.state.form.name.length > 3 && error === false)?true:(error !== false)?error:'Name needs to be over 3 charactors';
        error = (this.state.form.name.length < 24 && error === false)?true:(error !== false)?error:'Name needs to be less than 24 charactors';
        if(error)
        {
            createProduct(this.state.form).then(()=>{
                this.props.update();
                this.props.close();
            }).catch(error=>{console.log(error)});
        }
    }
    render(){
        return (
            <Fragment>
                <div className="input-group mb-3">
                    <div className="input-group-prepend">
                        <span className="input-group-text" id="namelabel">Product Name</span>
                    </div>
                    <input type="text" name='name' required={true} onChange={this.handleChange} value={this.state.form.name} className={(this.state.error && this.state.form.name.length < 3)?"form-control formerror":"form-control"} placeholder="Product Name" />
                </div>
                <div className="input-group mb-3">
                    <div className="input-group-prepend">
                        <span className="input-group-text" id="namelabel">Product Hook URL</span>
                    </div>
                    <input type="text" name='hook' required={true} onChange={this.handleChange} value={this.state.form.hook} className={(this.state.error && this.state.form.hook.length < 3)?"form-control formerror":"form-control"} placeholder="Hook" />
                </div>
                <div className="input-group mb-3">
                    <div className="input-group-prepend">
                        <span className="input-group-text" id="namelabel">Product Type</span>
                    </div>
                    <select className='form-control' name='type' onChange={this.handleChange} defaultValue='standard'>
                        <option value='web'>Web</option>
                        <option value='app'>App</option>
                        <option value='multi'>Multi</option>
                    </select>
                </div>
                <button className="btn btn-success nomargin floatright" onClick={this.create}>Create</button>
            </Fragment>
        )
    }
}
export default NewProduct;