import React, {Component, Fragment} from 'react';
import PageTop from "../../Global/pagetop/";
import NoContent from '../../Global/NoContent';
import {Store} from '../../../stores/Store';
import Loading from '../../Global/Loading';
import {
    getContainer, 
    getContainerGroups, 
    getContainerItems, 
    deleteContainerItem, 
    updateContainerItems, 
    deleteContainerGroup, 
    repositionContainerItem, 
    repositionContainerGroup
} from '../../../data/containers';
import {getFeed} from '../../../data/feeds';
import ContainerViewButtons from './ContainerViewButtons';
import Inputs from './Inputs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faTrash, faEdit, faPlus, faClone, faEye, faEyeSlash, faChevronDown, faChevronUp
} from '@fortawesome/free-solid-svg-icons';
import EditContainerGroup from './EditContainerGroup';
import ModalAlert from '../../Global/ModalAlert';
import NewContainerItem from './NewContainerItem';
import CloneContainerGroup from './CloneContainerGroup';
import {isNumber} from '../../../helpers';

class ContainerView extends Component {
    state = {
        form:{
        },
        changeArray:[],
        container_id:this.props.match.params.container_id,
        container:null,
        groups:null,
        items:null,
        feed:null,
        saving:false,
        type:(this.props.match.params.type)?this.props.match.params.type:null,
        show:null
    }
    componentDidMount(){
        this.updateItems();
    }
    handleMultiSelectChange = (name, array) => {
        let form = {...this.state.form};
        form[name] = JSON.stringify(array);
        this.setState({
          form:form
        });
    }
    handleChange = (e, type) => {
        let form = {...this.state.form};
        if(type === 'number'){
            form[e.target.name] = (isNumber(e.target.value))?e.target.value:form[e.target.name];
        }else{
            form[e.target.name] = e.target.value;
        }
        this.setState({
          form:form
        });
    }
    imageUpdate = (img, id) => {
        let form = {...this.state.form}
        form[id] = img;
        this.setState({
          form:form
        });
    }
    clear = (id) => {
        let form = {...this.state.form}
        form[id] = '';
        this.setState({
          form:form
        });
    }
    deleteItem = (item_id) => {
        deleteContainerItem(this.context.product_id, this.state.container_id, item_id).then(()=>{
            this.updateItems();
        });
    }
    saveGroup = async () => {
        await this.setState({
            saving:true
        });
        updateContainerItems(this.context.product_id, this.state.container_id, this.state.form).then(()=>{
            setTimeout(()=>{this.setState({
                saving:false
            })}, 1000);
        });
    }
    deleteGroup = (group_id) => {
        deleteContainerGroup(this.context.product_id, this.state.container_id, group_id).then(()=>{
            this.updateItems();
        });
        return null;
    }
    updateItems = async () =>{
        getContainer(this.context.product_id, this.state.container_id).then((res)=>{
            this.setState({
                container:res.data
            });
            if(res.data.feed_id)
            {
                getFeed(this.context.product_id, res.data.feed_id).then((res)=>{
                    this.setState({
                        feed:res.data
                    });
                });
            }
        });
        await getContainerGroups(this.context.product_id, this.state.container_id).then((res)=>{
            let sort =res.data.sort((a, b) => (a.position > b.position) ? 1 : -1);
            this.setState({
                groups:sort
            });
        });
        await getContainerItems(this.context.product_id, this.state.container_id).then((res)=>{
            let sort =res.data.sort((a, b) => (a.position > b.position) ? 1 : -1);
            this.setState({
                items:sort
            });
        });
        
    }

    clone = async () =>{
        this.context.alert('Clone Container');
    }
    reorder = async (id, group_id, newPosition) => {
        repositionContainerItem(this.context.product_id, this.state.container_id, id, group_id, newPosition).then(()=>{
            getContainerItems(this.context.product_id, this.state.container_id).then((res)=>{
                let sort =res.data.sort((a, b) => (a.position > b.position) ? 1 : -1);
                this.setState({
                    items:sort
                });
            });
        });
    }
    reordergroup = async (group_id, newPosition) => {
        repositionContainerGroup(this.context.product_id, this.state.container_id, group_id, newPosition).then(()=>{
            getContainerGroups(this.context.product_id, this.state.container_id).then((res)=>{
                let sort =res.data.sort((a, b) => (a.position > b.position) ? 1 : -1);
                this.setState({
                    groups:sort
                });
            });
        });
    }
    breadcrumbs = () => {
        let breadcrumbs = [];
        breadcrumbs.push({
            name:'products',
            uri:'/'
        });
        breadcrumbs.push({
            name:this.context.product.name,
            uri:'/product/'+this.context.product_id
        });
        if(this.state.feed)
        {
            breadcrumbs.push({
                name:'Feeds',
                uri:'/product/'+this.context.product_id+'/feeds'
            });
            breadcrumbs.push({
                name:this.state.feed.name,
                uri:'/product/'+this.context.product_id+'/feeds/'+this.state.container.feed_id
            });
        }else{
            breadcrumbs.push({
                name:'Containers',
                uri:'/product/'+this.context.product_id+'/containers/'
            });
        }
        breadcrumbs.push({
            name:this.state.container.name,
            uri:'/product/'+this.context.product_id+'/containers/view/'+this.state.container_id
        });
        return breadcrumbs
    }
    filterGroupItems = (group_id) => {
        return this.state.items.filter((item)=>{ return item.group_id === group_id}); 
    }
    render(){
        return (this.state.container)?(
            <Fragment>
                <PageTop 
                    name={this.state.container.name} 
                    breadcrumbs={this.breadcrumbs()} 
                    buttons={
                        <ContainerViewButtons 
                            save={this.saveGroup} 
                            update={this.updateItems} 
                            container_id={this.state.container_id}
                            saving={this.state.saving}
                        />
                    }
                />
                <div className='inner'>
                    <div className="row">
                        <div className="col-12">
                            {(this.state.groups && this.state.groups.length > 0)?(
                                this.state.groups.map((group, i)=>{
                                    return (
                                        <div key={i} className={'card containergroup '+((this.state.show === group.id)?'':'inactive')}>
                                            <div className='card-header' onClick={()=>{
                                                        this.setState({
                                                            show:(this.state.show === group.id)?null:group.id
                                                        })
                                                    }} >
                                                        <span 
                                                    className={' floatleft btn btn-none btn-sm'} 
                                                    
                                                >
                                                    <FontAwesomeIcon icon={(this.state.show === group.id)?faEye:faEyeSlash} />
                                                </span>
                                                {group.name}
                                            </div>
                                                {
                                                    (this.state.show === group.id)
                                                    &&
                                                        (
                                                            <Fragment>
                                                                <div className="groupBar">
                                                                    {(group.position !== 0)&&<span 
                                                                        className={'floatleft btn btn-none btn-sm'} 
                                                                        onClick={()=>{
                                                                            this.reordergroup(group.id, group.position-1);
                                                                        }} 
                                                                    >
                                                                        <FontAwesomeIcon icon={faChevronUp} />
                                                                    </span>}
                                                                    {(this.state.groups.length !== i+1)&&<span 
                                                                        className={'floatleft btn btn-none btn-sm'} 
                                                                        onClick={()=>{
                                                                            this.reordergroup(group.id, group.position+1);
                                                                        }} 
                                                                    >
                                                                        <FontAwesomeIcon icon={faChevronDown} />
                                                                    </span>}
                                                                    <span 
                                                                        onClick={()=>{
                                                                            if(window.confirm('Are you sure you would like to delete '+group.name)){
                                                                                this.deleteGroup(group.id);
                                                                            }
                                                                        }} 
                                                                        className={'boxbutton floatright btn btn-none btn-sm warning'} 
                                                                        title='Delete Group'
                                                                    >
                                                                        <FontAwesomeIcon icon={faTrash} />
                                                                    </span>
                                                                    <ModalAlert 
                                                                        buttonClassName='boxbutton floatright btn btn-none btn-sm' 
                                                                        buttonType='none'
                                                                        buttonIcon={<Fragment><FontAwesomeIcon icon={faClone} /></Fragment>} 
                                                                        title={'Clone Block'} 
                                                                        content={<CloneContainerGroup container_id={this.state.container_id} data={group} update={this.updateItems} clone={this.clone} />}
                                                                        size='lg'
                                                                    />
                                                                    <ModalAlert 
                                                                        buttonClassName='boxbutton floatright btn btn-none btn-sm' 
                                                                        buttonType='none'
                                                                        buttonIcon={<Fragment><FontAwesomeIcon icon={faEdit} /></Fragment>} 
                                                                        title={'Edit'} 
                                                                        content={<EditContainerGroup container_id={this.state.container_id} data={group} update={this.updateItems} />}
                                                                        size='lg'
                                                                    />
                                                                    <ModalAlert 
                                                                        buttonClassName='boxbutton floatright' 
                                                                        buttonType='none'
                                                                        buttonIcon={<Fragment><FontAwesomeIcon icon={faPlus} /></Fragment>} 
                                                                        title={'New Item'} 
                                                                        content={<NewContainerItem update={this.updateItems} container_id={this.state.container_id} group_id={group.id} />}
                                                                        size='lg'
                                                                    />
                                                                </div>
                                                                <div className='card-body'>
                                                                    
                                                                    {
                                                                        (this.state.items && this.state.items.length > 0)
                                                                        ?
                                                                            this.filterGroupItems(group.id).map((item, b)=>{
                                                                                return <Inputs 
                                                                                            key={b} 
                                                                                            reorder={this.reorder} 
                                                                                            arrayPosition={b}
                                                                                            last={(item.position) === (this.filterGroupItems(group.id).length-1)} 
                                                                                            currentValue={this.state.form[item.id]} 
                                                                                            container_id={this.state.container_id} 
                                                                                            handleChange={this.handleChange}
                                                                                            handleMultiSelectChange={this.handleMultiSelectChange}
                                                                                            imageUpdate={this.imageUpdate} 
                                                                                            update={this.updateItems} 
                                                                                            clear={this.clear} 
                                                                                            item={item} 
                                                                                            delete={this.deleteItem} 
                                                                                        />
                                                                            })
                                                                        :
                                                                            <NoContent text="No content in this group"/>
                                                                    }
                                                                </div>
                                                            </Fragment>
                                                        )
                                                }
                                            
                                        </div>
                                    )
                                })
                            ):<NoContent text="No content in this template yet" />}
                        </div>
                    </div>
                </div>
            </Fragment>
        ):(
            <Fragment>
                <PageTop name={'Loading Container'} />
                <div className='inner'>
                    <Loading />
                </div>
            </Fragment>
        );
    }
}
ContainerView.contextType = Store; // This part is important to access context values
export default ContainerView;