import  {db} from '../firebase';

export const getContainers = async (product_id) => {
    let responce = {
        success:true,
        data:[]
    }
    await db.collection('products').doc(product_id).collection('containers').orderBy("created", "desc").get().then(snapshot => {
        let containers = [];
        if(!snapshot.empty)
        {
            snapshot.forEach(doc => {
                let container = doc.data();
                container.id = doc.id;
                containers.push(container)
            });
            responce.data = containers;
        }
        return
    }).catch((error)=>{
        responce.success = false;
        responce.error = error;
        delete responce.data;
        return 
    });
    
    return responce;
}
export const getFeedContainers = async (product_id, feed_id) => {
    let responce = {
        success:true,
        data:[]
    }
    let all = [];
    let getcontainers = db.collection('products').doc(product_id).collection('containers').where('feeds', 'array-contains', feed_id).orderBy("created", "desc").get()
    let gettemplates = db.collection('products').doc(product_id).collection('containers').where('template', '==', true).orderBy("created", "desc").get()
    
    const [
        containersSnapshot,
        templatesSnapshot
      ] = await Promise.all([getcontainers, gettemplates]);
    
    const containers = containersSnapshot.docs;
    const templates = templatesSnapshot.docs;
    all.concat(containers, templates).forEach(doc => {
        let container = doc.data();
        container.id = doc.id;
        responce.data.push(container);
    });
    return responce;
}
export const getFeedCatContainers = async (product_id, cat_id) => {

    let responce = {
        success:true,
        data:[]
    }
    let all = [];
    let getcontainers = db.collection('products').doc(product_id).collection('containers').where("category", "array-contains", cat_id).orderBy("created").get()
    let gettemplates = db.collection('products').doc(product_id).collection('containers').where('template', '==', true).orderBy("created").get()
    
    const [
        containersSnapshot,
        templatesSnapshot
      ] = await Promise.all([getcontainers, gettemplates]);
    
    const containers = containersSnapshot.docs;
    const templates = templatesSnapshot.docs;
    all.concat(containers, templates).forEach(doc => {
        let container = doc.data();
        container.id = doc.id;
        responce.data.push(container);
    });


    return responce;
}
export const getContainer = async (product_id, container_id) => {
    let responce = {
        success:true,
        data:[]
    }
    await db.collection('products').doc(product_id).collection('containers').doc(container_id).get().then(doc => {
        let data = doc.data();
        responce.data = data
        return
    }).catch((error)=>{
        responce.success = false;
        responce.error = error;
        delete responce.data;
        return 
    });
    return responce;
}
export const createContainer = async (product_id, form, feed_id) => {
    feed_id = feed_id || null;
    let responce = {
        success:true,
    }
    await db.collection('products').doc(product_id).collection('containers').add(
        {
            name:form.name,
            active:false,
            feed_id:(form.global)?'global':feed_id,
            feeds:(form.feeds)?form.feeds:[],
            template:form.template,
            type:form.type,
            category:(form.categories)?form.categories:[],
            created:new Date().getTime(),
        }
    ).then((ref) => {
        responce.id = ref.id;
    }).catch((error)=>{
        responce.success = false;
        responce.error = error;
        delete responce.id;
        return 
    });
    if(responce.id && form.template_id && form.template_id.length > 0)
    {
        let groupref = [];
        await getContainerGroups(product_id, form.template_id).then(async(res)=>{
            let data = res.data;
            for(let i=0; data.length > i; i++){
                await db.collection('products').doc(product_id).collection('containers').doc(responce.id).collection('groups').add(data[i]).then((res)=>{
                    let obj = {
                        old_group_id:data[i].id,
                        new_group_id:res.id
                    }
                    groupref.push(obj);
                });
            }
        })
        await getContainerItems(product_id, form.template_id).then((res)=>{
            let data = res.data;
            for(let i=0; data.length > i; i++)
            {
                for(let b = 0; groupref.length > b; b++)
                {
                    if(groupref[b].old_group_id === data[i].group_id)
                    {
                        data[i].group_id = groupref[b].new_group_id;
                        db.collection('products').doc(product_id).collection('containers').doc(responce.id).collection('items').add(data[i]);
                        break;
                    }
                }
            }
        });
    }
    return responce;
}

export const updateContainer = async (product_id, container_id, form) => {
    let responce = {
        success:true,
        data:[]
    }
    await db.collection('products').doc(product_id).collection('containers').doc(container_id).update(
        {
            name:form.name,
            category:(form.categories)?form.categories:[],
            feeds:(form.feeds)?form.feeds:[],
            buckets:(form.buckets)?form.buckets:[],
            updated:new Date().getTime()
        }
    ).then(ref => {
        responce.data = ref
    }).catch((error)=>{
        responce.success = false;
        responce.error = error;
        delete responce.data;
        return 
    });
    return responce;
}
export const toggleContainer = async (product_id, container_id, state) => {
    let responce = {
        success:true,
        data:[]
    }
    await db.collection('products').doc(product_id).collection('containers').doc(container_id).update(
        {
            active:!state
        }
    ).then(ref => {
        responce.data = ref
    }).catch((error)=>{
        responce.success = false;
        responce.error = error;
        delete responce.data;
        return 
    });
    return responce;
}
export const deleteContainer = async (product_id, container_id) => {
    let responce = {
        success:true
    }
    const base = db.collection('products').doc(product_id).collection('containers').doc(container_id);
    await base.delete().catch((error)=>{
        responce.success = false;
        responce.error = error;
        return 
    });
    await deleteAllItems(product_id, container_id);
    await deleteAllGroups(product_id, container_id);
    return responce;
}
export const deleteAllItems = async (product_id, container_id) => {
    const base = db.collection('products').doc(product_id).collection('containers').doc(container_id);
    await base.collection('items')
            .get().then((snapshot)=>{
                snapshot.forEach(doc => {
                    deleteContainerItem(product_id, container_id, doc.id);
                });
            }).catch(error=>console.log(error));
}
export const deleteAllGroups = async (product_id, container_id) => {
    const base = db.collection('products').doc(product_id).collection('containers').doc(container_id);
    await base.collection('groups')
            .get().then((snapshot)=>{
                snapshot.forEach(doc => {
                    deleteContainerGroup(product_id, container_id, doc.id);
                });
            }).catch(error=>console.log(error));
}
export const createContainerGroup = async (product_id, container_id, form) => {
    let responce = {
        success:true,
        data:[]
    }
    let groupslength = 0;
    await getContainerGroups(product_id, container_id).then((res)=>{
        groupslength = (res.data)?(res.data.length):0;
        
    });
    await db.collection('products').doc(product_id).collection('containers').doc(container_id).collection('groups').add(
        {
            name:form.name,
            slug:form.slug,
            position:groupslength,
            created:new Date().getTime(),
        }
    ).then(ref => {
        responce.data = ref
    }).catch((error)=>{
        responce.success = false;
        responce.error = error;
        delete responce.data;
        return 
    });
    return responce;
}
export const updateContainerGroup = async (product_id, container_id, group_id, form) => {
    let responce = {
        success:true,
        data:[]
    }
    await db.collection('products').doc(product_id).collection('containers').doc(container_id).collection('groups').doc(group_id).update(
        {
            name:form.name,
            slug:form.slug,
            updated:new Date().getTime(),
        }
    ).then(ref => {
        responce.data = ref
    }).catch((error)=>{
        responce.success = false;
        responce.error = error;
        delete responce.data;
        return 
    });
    return responce;

}
export const getContainerGroups = async (product_id, container_id) => {
    let responce = {
        success:true,
        data:[]
    }
    await db.collection('products').doc(product_id).collection('containers').doc(container_id).collection('groups').get().then(snapshot => {
        let containergroups = [];
        if(!snapshot.empty)
        {
            snapshot.forEach(doc => {
                let group = doc.data();
                group.id = doc.id;
                containergroups.push(group)
            });
            responce.data = containergroups;
        }
        return
    }).catch((error)=>{
        responce.success = false;
        responce.error = error;
        delete responce.data;
        return 
    });
    return responce;
}
export const getContainerGroup = async (product_id, container_id, group_id) => {
    let responce = {
        success:true,
        data:[]
    }
    await db.collection('products').doc(product_id).collection('containers').doc(container_id).collection('groups').doc(group_id).get().then(doc => {
        let data = doc.data();
        responce.data = data
        responce.data.id = doc.id;
        return
    }).catch((error)=>{
        responce.success = false;
        responce.error = error;
        delete responce.data;
        return 
    });
    return responce;
}
export const deleteContainerGroup = async (product_id, container_id, group_id) => {
    let responce = {
        success:true
    }
    await db.collection('products').doc(product_id).collection('containers').doc(container_id).collection('groups').doc(group_id).delete().catch((error)=>{
        responce.success = false;
        responce.error = error;
        return 
    });
    await db.collection('products').doc(product_id).collection('containers').doc(container_id).collection('items').get().then((snapshot)=>{
        if(!snapshot.empty)
        {
            snapshot.forEach(doc => {
                let data = doc.data();
                if(data.group_id === group_id)
                {
                    doc.ref.delete();
                }
            });
        }
        return
    }).catch((error)=>{
        responce.success = false;
        responce.error = error;
        return 
    });
    return responce;
}
export const createContainerItem = async (product_id, container_id, form) => {
    let responce = {
        success:true,
        data:[]
    }
    let groupitems = await getContainerGroupItems(product_id, container_id, form.group);
    let position = (groupitems.data)?(groupitems.data.length):0;
    await db.collection('products').doc(product_id).collection('containers').doc(container_id).collection('items').add(
        {
            name:form.name,
            slug:form.slug,
            type:form.type,
            linked:form.linked,
            placeholder:(form.placeholder)?form.placeholder:null,
            muted:(form.muted)?form.muted:null,
            group_id:(form.group)?form.group:null,
            select:(form.type === 'select')?form.select:null,
            position:position,
            created:new Date().getTime()
        }
    ).then(ref => {
        responce.data = ref
    }).catch((error)=>{
        responce.success = false;
        responce.error = error;
        delete responce.data;
        return 
    });
    return responce;
}
export const repositionContainerItem = async (product_id, container_id, item_id, group_id, newPosition) => {
    let responce = {
        success:true
    }
    let groupItems = await getContainerGroupItems(product_id, container_id, group_id);
    let current = groupItems.data.find((item)=>{ return item.id === item_id });
    let previous = (newPosition < current.position)?groupItems.data.find((item)=>{ return item.position === newPosition}):null;
    let next = (newPosition > current.position)?groupItems.data.find((item)=>{ return item.position === newPosition}):null;

    if(current !== null)
    {

        if(previous !== null && previous !== undefined && newPosition < current.position)
        {
            await db.collection('products').doc(product_id).collection('containers').doc(container_id).collection('items').doc(previous.id).update(
                {
                    position:(newPosition+1),
                }
            );
        }else if(next !== null && next !== undefined && newPosition > current.position){
            await db.collection('products').doc(product_id).collection('containers').doc(container_id).collection('items').doc(next.id).update(
                {
                    position:(newPosition-1),
                }
            );
        }
        await db.collection('products').doc(product_id).collection('containers').doc(container_id).collection('items').doc(item_id).update(
            {
                position:newPosition,
            }
        )
    }
    return responce;
}
export const repositionContainerGroup = async (product_id, container_id, group_id, newPosition) => {
    let responce = {
        success:true
    }
    let group = await getContainerGroups(product_id, container_id);
    let current = group.data.find((item)=>{ return item.id === group_id });
    let previous = (newPosition < current.position)?group.data.find((item)=>{ return item.position === newPosition}):null;
    let next = (newPosition > current.position)?group.data.find((item)=>{ return item.position === newPosition}):null;
    if(current !== null)
    {

        if(previous !== null && previous !== undefined && newPosition < current.position)
        {
            await db.collection('products').doc(product_id).collection('containers').doc(container_id).collection('groups').doc(previous.id).update(
                {
                    position:(newPosition+1),
                }
            );
        }else if(next !== null && next !== undefined && newPosition > current.position){
            await db.collection('products').doc(product_id).collection('containers').doc(container_id).collection('groups').doc(next.id).update(
                {
                    position:(newPosition-1),
                }
            );
        }
        await db.collection('products').doc(product_id).collection('containers').doc(container_id).collection('groups').doc(group_id).update(
            {
                position:newPosition,
            }
        )
    }
    return responce;
}
export const updateContainerItem = async (product_id, container_id, item_id, form) => {
    let responce = {
        success:true,
        data:[]
    }
    await db.collection('products').doc(product_id).collection('containers').doc(container_id).collection('items').doc(item_id).update(
        {
            name:form.name,
            slug:form.slug,
            placeholder:(form.placeholder)?form.placeholder:null,
            muted:(form.muted)?form.muted:null,
            group_id:(form.group)?form.group:null,
            select:(form.select)?form.select:null
        }
    ).then(ref => {
        responce.data = ref
    }).catch((error)=>{
        responce.success = false;
        responce.error = error;
        delete responce.data;
        return 
    });
    return responce;
}
export const getContainerItems = async (product_id, container_id) => {
    let responce = {
        success:true,
        data:[]
    }
    await db.collection('products').doc(product_id).collection('containers').doc(container_id).collection('items').get().then(snapshot => {
        let containeritems = [];
        if(!snapshot.empty)
        {
            snapshot.forEach(doc => {
                let item = doc.data();
                item.id = doc.id;
                containeritems.push(item)
            });
            responce.data = containeritems;
        }
        return
    }).catch((error)=>{
        responce.success = false;
        responce.error = error;
        delete responce.data;
        return 
    });
    return responce;
}
export const getContainerGroupItems = async (product_id, container_id, group_id) => {
    let responce = {
        success:true,
        data:[]
    }
    await db.collection('products').doc(product_id).collection('containers').doc(container_id).collection('items').get().then(snapshot => {
        let containeritems = [];
        if(!snapshot.empty)
        {
            snapshot.forEach(doc => {
                let item = doc.data();
                item.id = doc.id;
                containeritems.push(item)
            });
            responce.data = containeritems.filter((item)=>{
                return item.group_id === group_id;
            });
        }
        return
    }).catch((error)=>{
        responce.success = false;
        responce.error = error;
        delete responce.data;
        return 
    });
    return responce;
}
export const getContainerItem = async (product_id, container_id, item_id) => {
    let responce = {
        success:true,
        data:[]
    }
    await db.collection('products').doc(product_id).collection('containers').doc(container_id).collection('items').doc(item_id).get().then(doc => {
        let data = doc.data();
        responce.data = data
        return
    }).catch((error)=>{
        responce.success = false;
        responce.error = error;
        delete responce.data;
        return 
    });
    return responce;
}
export const deleteContainerItem = async (product_id, container_id, item_id) => {
    let responce = {
        success:true
    }
    await db.collection('products').doc(product_id).collection('containers').doc(container_id).collection('items').doc(item_id).delete().catch((error)=>{
        responce.success = false;
        responce.error = error;
        return 
    });
    return responce;
}
export const updateContainerItems = async (product_id, container_id, form) => {
    let responce = {
        success:true
    }
    let keys = [];
    for(var key in form){
        keys.push(key);
    }
    /// updating the items
    for(let i = 0; keys.length > i; i++){
        let value = form[keys[i]];
        db.collection('products').doc(product_id).collection('containers').doc(container_id).collection('items').doc(keys[i]).update({
            value:value
        })
    }
    return responce;
}
export const getContainerBuckets = async (product_id, type) =>{
    let responce = {
        success:true,
        data:[]
    }
    await db.collection('products').doc(product_id).collection('buckets').where('type', '==', type).get().then(snapshot => {
        let buckets = [];
        if(!snapshot.empty)
        {
            snapshot.forEach(doc => {
                let item = doc.data();
                item.id = doc.id;
                buckets.push(item);
            });
            responce.data = buckets.filter((item)=>{
                return item.type === type;
            });
        }
        return
    }).catch((error)=>{
        responce.success = false;
        responce.error = error;
        delete responce.data;
        return 
    });
    return responce;
}
export const updateContainerBucket = async (product_id, bucket_id, form) =>{
    let responce = {
        success:true,
        data:[]
    }
    await db.collection('products').doc(product_id).collection('buckets').doc(bucket_id).update(
        {
            name:form.name
        }
    ).then(ref => {
        responce.data = ref
    }).catch((error)=>{
        responce.success = false;
        responce.error = error;
        delete responce.data;
        return 
    });
    return responce;
}
export const deleteContainerBucket= async (product_id, bucket_id) =>{
    let responce = {
        success:true
    }
    await db.collection('products').doc(product_id).collection('buckets').doc(bucket_id).delete().catch((error)=>{
        responce.success = false;
        responce.error = error;
        return 
    });
    return responce;
}
export const createContainerBucket = async (product_id, form) => {
    let responce = {
        success:true,
        data:[]
    }
    await db.collection('products').doc(product_id).collection('buckets').add(
        {
            name:form.name,
            type:form.type,
            containers:[],
            created:new Date().getTime()
        }
    ).then(ref => {
        responce.data = ref
    }).catch((error)=>{
        responce.success = false;
        responce.error = error;
        delete responce.data;
        return 
    });
    return responce;
}
