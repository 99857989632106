import React, {Fragment, Component} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
faGlobe, faMobile, faNetworkWired, faCube, faCubes
} from '@fortawesome/free-solid-svg-icons';
import Processing from '../../Global/Processing';
import {Store} from "../../../stores/Store";
import ButtonBar from './ButtonBar';

class ProductCard extends Component{
    state = {
        removing:false
    }

    render(){
        return (
            <li className="col-12 col-md-6 col-lg-4 card_object">
                <div className={"card "+((!this.props.data.active)&&'inactive')}>
                    <div className="card-header card-header-borderless d-flex justify-content-between">
                        <div>
                            {(this.props.data.type === 'multi')&&<FontAwesomeIcon icon={faNetworkWired} /> }
                            {(this.props.data.type === 'app')&&<FontAwesomeIcon icon={faMobile} /> }
                            {(this.props.data.type === 'web')&&<FontAwesomeIcon icon={faGlobe} /> }
                            {' '+this.props.data.name}
                        </div>
                        <div>
                            <div className={'dot click '+((!this.props.data.active)&&'warning')} title='Amount of Pages'>
                                {(this.props.data.type === 'multi')&&(<Fragment><FontAwesomeIcon icon={faNetworkWired} /> Multi Access Product</Fragment>)}
                                {(this.props.data.type === 'app')&&(<Fragment><FontAwesomeIcon icon={faMobile} /> Mobile App</Fragment>)}
                                {(this.props.data.type === 'web')&&(<Fragment><FontAwesomeIcon icon={faGlobe} /> Website Product</Fragment>)}
                            </div>
                            {(this.props.data.active)?<div className='dot click' title='Amount of Pages'>Active</div>:<div className='dot warning click' title='Amount of Pages'>Inactive</div>}
                        </div>
                    </div>
                    <div className="card-body">
                        <div className="media">
                            <div className="media-body siteCard">
                                {(this.state.removing)?(
                                    <Processing />
                                ):(
                                    <ul className="list-group">
                                        <li className="list-group-item">
                                            <Fragment>
                                            <FontAwesomeIcon icon={faCube} /> Containers
                                                <div className={'dot click floatright '+((!this.props.data.active)&&'warning')} title='Amount of Pages'>{this.props.data.container_amount}</div>
                                            </Fragment>
                                        </li>
                                        <li className="list-group-item">
                                            <Fragment>
                                            <FontAwesomeIcon icon={faCubes} /> Feeds
                                                <div className={'dot click floatright '+((!this.props.data.active)&&'warning')} title='Amount of Feeds'>{this.props.data.feed_amount}</div>
                                            </Fragment>
                                        </li>
                                    </ul>
                                )}
                            </div>
                        </div>
                    </div>
                    {(!this.state.removing)
                        &&<ButtonBar
                            update={this.props.update}
                            data={this.props.data}
                        />}
                </div>
            </li>
        )
    }
}
ProductCard.contextType = Store; // This part is important to access context values
export default ProductCard;