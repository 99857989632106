import React, {Component} from 'react';
import {Login, ResendVerificationEmail} from "../../data/users";
import {Store} from "../../stores/Store";
import {Alert} from 'reactstrap';
import {NavLink} from 'react-router-dom';

class SignIn extends Component {
    state = {
        loginclass:'',
        error:'',
        initial:'none',
        email:'',
        password:'',
        verified:null
    }
    resend = async () =>{
        ResendVerificationEmail().then((res)=>{
            this.setState({
                verified:'sent'
            })
        }).catch((error)=>{
            this.setState({
                verified:'error'
            })
        });
    }
    login = async (event) =>{
        event.preventDefault();
        await Login(this.state.email, this.state.password).then(async (res)=>{
            if(res.success === true){
                if(res.data.emailVerified === false){
                    await this.setState({
                        verified:false
                    });
                }else{
                    await this.context.update({
                        token:res.data.token,
                        access:res.data.level,
                        uid:res.data.uid
                    });
                }
            }else{
                this.clear();
            }
        }).catch(async  (error) =>{
            this.clear();
        });   
    }
    clear = async () => {
        await this.setState({
            loginclass:'addshake',
            email:'',
            password:''
        });
        setTimeout(()=>{ this.setState({loginclass:'',})}, 1000)
    }
    handleEmailChange = (e) => {
        this.setState({email: e.target.value});
    }
    handlePasswordChange = (e) => {
        this.setState({password: e.target.value});
    }
    render(){
        return (
            <section>
                <div className="container align-self-start ">
                    <div className="row justify-content-center">
                        <div className="col-12 col-md-8 col-lg-7">
                            <div className={"card card-lg text-center "+this.state.loginclass}>
                                <div className="card-body">
                                    <div className="mb-5">
                                        <div>
                                            <img alt='logo' style={{width:'30%'}} src={process.env.REACT_APP_LOGO} />
                                        </div>
                                        <br/>
                                        <p>Sign in to your account to continue</p>
                                        <p>No Account - <NavLink to="/register">Register here</NavLink></p>
                                    </div>
                                    {(this.state.verified === false)&&(
                                        <div className='col-12'>
                                            <div className="alert alert-warning" role="alert">
                                                <p>Your account has not been verified please check your email account for your verification link!</p>
                                                <p>Please do check your junk folder!</p>
                                                <p>if it has been over 30 minutes please click <span onClick={this.resend}>here</span> to resend the link</p>
                                            </div>
                                        </div>
                                    )}
                                    {(!this.state.verified === 'sent')&&(
                                        <div className='col-12'>
                                            <div className="alert alert-warning" role="alert">
                                                <p>We have resent your verification link!</p>      
                                            </div>
                                        </div>
                                    )}
                                    {(!this.state.verified === 'error')&&(
                                        <div className='col-12'>
                                            <div className="alert alert-warning" role="alert">
                                                <p>There has been an error dealing with this request!</p>      
                                            </div>
                                        </div>
                                    )}
                                    <div className="row no-gutters justify-content-center">
                                        <form className="form-horizontal appform " onSubmit={this.login}>
                                            <div className="form-group">
                                                <label>Email Address</label>
                                                <input type="email" value={this.state.email} onChange={this.handleEmailChange} className="form-control form-control-lg"  required />
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="login-password">Password</label>
                                                <input type="password" value={this.state.password} className="form-control form-control-lg" onChange={this.handlePasswordChange} required />
                                            </div>
                                            {(this.state.error) && <Alert color="danger">{this.state.error}</Alert>}
                                            <div className="text-center mt-3">
                                                <button type="submit" className="btn btn-lg btn-primary">Log in</button>
                                            </div>
                                            <div className="text-center mt-3">
                                                <NavLink to="/forgotpassword">Forgot Password</NavLink>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}
SignIn.contextType = Store; // This part is important to access context values
export default SignIn;
