import React from 'react';
const Bool = (props) => {
    return (
        <div className="input-group mb-3">
            <div className="input-group-prepend">
                <div className="input-group-text">{props.item.name}</div>
            </div>
            <select 
                name={props.item.id} 
                className='form-control' 
                onChange={(e)=>{props.handleChange(e, 'bool')}} 
                value={(props.currentValue !== null && props.currentValue !== undefined)?props.currentValue:props.item.value} 
            >
                <option value='false'>False</option>
                <option value='true'>True</option>
            </select>
        </div>
    )
}
export default Bool;