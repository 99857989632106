import React, {Component} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faTrash, faBan
} from '@fortawesome/free-solid-svg-icons';
class DisableButton extends Component{
    state = {
        hover:false
    }
    toggle = async () => {
        await this.setState({
            hover:!this.state.hover
        });
    }
    render(){
        return (
            (this.props.user.disabled)
                                       ?
                                            <button 
                                                className="boxbutton btn btn-sm" 
                                                title='Disable User' 
                                                onMouseEnter={this.toggle} 
                                                onMouseOut={this.toggle} 
                                                onClick={()=>{this.props.enableUser(this.props.user)}} 
                                                style={{margin:'0px 5px'}}
                                            >
                                                <FontAwesomeIcon icon={faBan} /> {(this.state.hover)?'Enable':'Disabled'}
                                            </button>
                                       :
                                            <button 
                                                className="boxbutton btn btn-sm warning" 
                                                title='Disable User' onMouseEnter={this.toggle} 
                                                onMouseOut={this.toggle}  
                                                onClick={()=>{this.props.disableUser(this.props.user)}} 
                                                style={{margin:'0px 5px'}}
                                            >
                                                <FontAwesomeIcon icon={faBan} />
                                            </button>
        )
    }
}
class DeleteButton extends Component{
    render(){
        return <button className="boxbutton btn btn-sm warning" title='Delete User' onClick={()=>{this.props.delete(this.props.user)}} style={{margin:'0px 5px'}}><FontAwesomeIcon icon={faTrash} /></button>
    }
}

export {
    DisableButton,
    DeleteButton
}