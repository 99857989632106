import React from 'react';

const Number = (props) => {
    return (
        <div className="input-group mb-3">
            <div className="input-group-prepend">
                <div className="input-group-text">{props.item.name}</div>
            </div>
            <input 
                type='number' 
                name={props.item.id} 
                className='form-control' 
                placeholder={(props.item.placeholder)?props.item.placeholder:null}
                onChange={(e)=>{props.handleChange(e, 'number')}} 
                value={(props.currentValue !== null && props.currentValue !== undefined)?props.currentValue:props.item.value} 
            />
        </div>
    )
}
export default Number;