import React from 'react';
import { Editor } from '@tinymce/tinymce-react';

const Wysiwyg = (props) => {
    return (
        <Editor
            initialValue={(props.currentValue !== null && props.currentValue !== undefined)?decodeURIComponent(props.currentValue):(props.item.value)?decodeURIComponent(props.item.value):''}
            init={{
            height: 500,
            menubar: false,
            plugins: [
                'advlist autolink lists link image charmap print preview anchor',
                'searchreplace visualblocks code fullscreen',
                'insertdatetime media table paste code help wordcount'
            ],
            toolbar:
                'undo redo | formatselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat'
            }}
            onChange={(e) => {
                let object = {
                    target:{
                        value:encodeURIComponent(e.target.getContent()),
                        name:props.item.id
                    }
                };
                props.handleChange(object, 'wysiwyg');
            }}
        />
    )
}
export default Wysiwyg;