import React, {Component, Fragment} from 'react';
import PageTop from "../Global/pagetop/";
import ProductButtons from './ProductButtons';
import {Store} from '../../stores/Store';
import {getProduct} from '../../data/products';
import Loading from '../Global/Loading';

class Product extends Component {
    state = {
        product:null
    }
    componentDidMount(){
        this.context.update({
            product_id:this.props.match.params.id
        });
        getProduct(this.props.match.params.id).then((res)=>{
            this.setState({
                product:res.data,
            });
            this.context.update({
                product:res.data,
                hook:res.data.hook
            });
        })
    }
    render(){
        return (this.state.product)?(
            <Fragment>
                <PageTop name={this.state.product.name} buttons={<ProductButtons update={this.update} />} />
                <div className='inner'>
                    <div className='row'>
                        <div className='col-12'>
                            <div className='card'>
                                <div className='card-header'>
                                    API Calls 
                                </div>
                                <div className='card-body'>
                                    <div className="item-card">
                                        <div className="card-controls">
                                            <small className="text-muted floatleft">Get Global</small>
                                        </div>
                                        <div className="input-group mb-3">
                                            <div className="input-group-prepend">
                                                <div className="input-group-text">Get Global</div>
                                            </div>
                                            <input readOnly className="form-control" value="https://europe-west2-headless-cms-e7e51.cloudfunctions.net/getglobal?product_id=something" />
                                        </div>
                                    </div>
                                    <div className="item-card">
                                        <div className="card-controls">
                                            <small className="text-muted floatleft">Get Containers</small>
                                        </div>
                                        <div className="input-group mb-3">
                                            <div className="input-group-prepend">
                                                <div className="input-group-text">Get Containers</div>
                                            </div>
                                            <input readOnly className="form-control" value="https://europe-west2-headless-cms-e7e51.cloudfunctions.net/getcontainers?product_id=something" />
                                        </div>
                                    </div>
                                    <div className="item-card">
                                        <div className="card-controls">
                                            <small className="text-muted floatleft">Get View Containers</small>
                                        </div>
                                        <div className="input-group mb-3">
                                            <div className="input-group-prepend">
                                                <div className="input-group-text">Get View Containers</div>
                                            </div>
                                            <input readOnly className="form-control" value="https://europe-west2-headless-cms-e7e51.cloudfunctions.net/getviewcontainers?product_id=something" />
                                        </div>
                                    </div>
                                    <div className="item-card">
                                        <div className="card-controls">
                                            <small className="text-muted floatleft">Get Data Containers</small>
                                        </div>
                                        <div className="input-group mb-3">
                                            <div className="input-group-prepend">
                                                <div className="input-group-text">Get Data Containers</div>
                                            </div>
                                            <input readOnly className="form-control" value="https://europe-west2-headless-cms-e7e51.cloudfunctions.net/getdatacontainers?product_id=something" />
                                        </div>
                                    </div>
                                    <div className="item-card">
                                        <div className="card-controls">
                                            <small className="text-muted floatleft">Get Buckets</small>
                                        </div>
                                        <div className="input-group mb-3">
                                            <div className="input-group-prepend">
                                                <div className="input-group-text">Get Buckets</div>
                                            </div>
                                            <input readOnly className="form-control" value="https://europe-west2-headless-cms-e7e51.cloudfunctions.net/getbuckets?product_id=something" />
                                        </div>
                                    </div>
                                    <div className="item-card">
                                        <div className="card-controls">
                                            <small className="text-muted floatleft">Get Containers by Bucket</small>
                                        </div>
                                        <div className="input-group mb-3">
                                            <div className="input-group-prepend">
                                                <div className="input-group-text">Get Containers by Bucket</div>
                                            </div>
                                            <input readOnly className="form-control" value="https://europe-west2-headless-cms-e7e51.cloudfunctions.net/getcontainersbybucket?product_id=something&bucket_id=something" />
                                        </div>
                                    </div>
                                    <div className="item-card">
                                        <div className="card-controls">
                                            <small className="text-muted floatleft">Get Container</small>
                                        </div>
                                        <div className="input-group mb-3">
                                            <div className="input-group-prepend">
                                                <div className="input-group-text">Get Container</div>
                                            </div>
                                            <input readOnly className="form-control" value="https://europe-west2-headless-cms-e7e51.cloudfunctions.net/getcontainer?product_id=something&container_id=something" />
                                        </div>
                                    </div>
                                    <div className="item-card">
                                        <div className="card-controls">
                                            <small className="text-muted floatleft">Get Feeds</small>
                                        </div>
                                        <div className="input-group mb-3">
                                            <div className="input-group-prepend">
                                                <div className="input-group-text">Get Feeds</div>
                                            </div>
                                            <input readOnly className="form-control" value="https://europe-west2-headless-cms-e7e51.cloudfunctions.net/getfeeds?product_id=something" />
                                        </div>
                                    </div>
                                    <div className="item-card">
                                        <div className="card-controls">
                                            <small className="text-muted floatleft">Get Feed</small>
                                        </div>
                                        <div className="input-group mb-3">
                                            <div className="input-group-prepend">
                                                <div className="input-group-text">Get Feed</div>
                                            </div>
                                            <input readOnly className="form-control" value="https://europe-west2-headless-cms-e7e51.cloudfunctions.net/getfeed?product_id=something&feed_id=something" />
                                        </div>
                                    </div>
                                    <div className="item-card">
                                        <div className="card-controls">
                                            <small className="text-muted floatleft">Get Feed Category</small>
                                        </div>
                                        <div className="input-group mb-3">
                                            <div className="input-group-prepend">
                                                <div className="input-group-text">Get Feed Category</div>
                                            </div>
                                            <input readOnly className="form-control" value="https://europe-west2-headless-cms-e7e51.cloudfunctions.net/getfeedcategory?product_id=something&feed_id=something&cat_id=something" />
                                        </div>
                                    </div>
                                    <div className="item-card">
                                        <div className="card-controls">
                                            <small className="text-muted floatleft">Get Feed Category</small>
                                        </div>
                                        <div className="input-group mb-3">
                                            <div className="input-group-prepend">
                                                <div className="input-group-text">Get Feed Category</div>
                                            </div>
                                            <input readOnly className="form-control" value="https://europe-west2-headless-cms-e7e51.cloudfunctions.net/getfeedcategories?product_id=something&feed_id=something" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                </div>
            </Fragment>
        ):(
            <Fragment>
                <PageTop name={'Loading Product'} />
                <div className='inner'>
                    <Loading />
                </div>
            </Fragment>
        );
    }
}
Product.contextType = Store; // This part is important to access context values
export default Product;
