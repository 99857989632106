import React, {Fragment, Component} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faObjectGroup
} from '@fortawesome/free-solid-svg-icons';
import Processing from '../../Global/Processing';
import {Store} from "../../../stores/Store";
import ButtonBar from './ButtonBar';

class ContainerBuckets extends Component{
    state = {
        removing:false
    }
    render(){
        return (
            <li className="col-12 col-md-6 col-lg-4 card_object">
                <div className={"card "}>
                    <div className="card-header card-header-borderless d-flex justify-content-between">
                    <div><FontAwesomeIcon icon={ faObjectGroup } />{this.props.data.name}</div>
                    </div>
                    <div className="card-body">
                        <div className="media">
                            <div className="media-body siteCard">
                                {(this.state.removing)?(
                                    <Processing />
                                ):(
                                    <ul className="list-group">
                                        <li className="list-group-item">
                                            <Fragment>
                                            <FontAwesomeIcon icon={faObjectGroup} /> Containers
                                                <div className={'dot click floatright'} title='Amount of Items'>{this.props.data.containers.length}</div>
                                            </Fragment>
                                        </li>
                                    </ul>
                                )}
                            </div>
                        </div>
                    </div>
                    {(!this.state.removing)
                        &&<ButtonBar
                            data={this.props.data}
                            update={this.props.update}
                            type={this.props.type}
                        />}
                </div>
            </li>
        )
    }
}
ContainerBuckets.contextType = Store; // This part is important to access context values
export default ContainerBuckets;