import React, {Fragment} from 'react';
import Select from 'react-select';

const SingleSelect = (props) =>{
    return (!props.simple)?(
                    <Fragment>
                        <label>{props.title}</label>
                        <Select
                            defaultValue={props.selected}
                            onChange={props.handleChange}
                            closeMenuOnSelect={true}
                            options={props.dateSource}
                        />
                    </Fragment>
                ):(
                        <Select
                            defaultValue={props.selected}
                            onChange={props.handleChange}
                            closeMenuOnSelect={true}
                            options={props.dateSource}
                            placeholder={props.title}
                        />
                )
}
export default SingleSelect;