import React, {Component, Fragment} from 'react';
import {updateFeed} from '../../data/feeds';
import {Store} from '../../stores/Store';

class EditFeed extends Component {
    state = {
        form:{
            name:(this.props.data.name)?this.props.data.name:''
        },
        add:false
    }
    handleChange = (e) => {
        let form = {...this.state.form}
        form[e.target.name] = e.target.value;
        this.setState({
          form:form
        });
    }
    update = async() =>{
        let error = false;
        error = (this.state.form.name.length > 3 && error === false)?true:(error !== false)?error:'Name needs to be over 3 charactors';
        error = (this.state.form.name.length < 24 && error === false)?true:(error !== false)?error:'Name needs to be less than 24 charactors';
        error = (this.state.useposterimage && this.state.form.posterimage.length < 3 && error === false)?true:(error !== false)?error:'please set your poster image';
        if(error)
        {
            updateFeed(this.context.product_id, this.props.data.id, this.state.form).then(()=>{
                this.props.update();
                this.props.close();
            });
        }
    }
    render(){
        return (
            <Fragment>
                <div className='row'>
                    <div className='col-12'>
                        <div className="input-group mb-3">
                            <div className="input-group-prepend">
                                <span className="input-group-text" id="namelabel">Feed Name</span>
                            </div>
                            <input type="text" name='name' required={true} onChange={this.handleChange} value={this.state.form.name} className={(this.state.error && this.state.form.name.length < 3)?"form-control formerror":"form-control"} placeholder="Feed Name" />
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-12'>
                        <button className="btn btn-success nomargin floatright" onClick={this.update}>Update</button>
                    </div>
                </div>
            </Fragment>
        )
    }
}
EditFeed.contextType = Store; // This part is important to access context values
export default EditFeed;