import React, {Component, Fragment} from 'react';
import PageTop from "../Global/pagetop";
import NoContent from '../Global/NoContent';
import {Store} from '../../stores/Store';
import Loading from '../Global/Loading';
import {getFeedContainers, getFeedCatContainers} from '../../data/containers';
import {getFeed, getFeedCategories} from '../../data/feeds';
import ContainerButtons from '../Containers/ContainerButtons';
import ContainerCard from '../Containers/ContainerCard';
import Categories from './Categories';
class FeedContainers extends Component {
    state = {
        containers:null,
        feed_id:(this.props.match.params.feed_id)?this.props.match.params.feed_id:null,
        view:'containers',
        feed:null,
        cat_id:null,
        cat_name:null,
        categories:[]
    }
    componentDidMount(){
        const {cat_id} = this.props.match.params;
        
        getFeedCategories(this.context.product_id, this.state.feed_id).then((res)=>{
            this.setState({
                categories:res.data
            });
            for(let cat of res.data){
                if(cat.id === cat_id){
                    this.setState({
                        cat_id:cat.id,
                        cat_name:cat.name
                    });
                }
            }
            this.getFeed().then((res)=>{
                if(res === true)
                {
                    this.update();
                }else{
                    this.setState({
                        containers:[]
                    });
                }
            });
        });
    }
    update = () =>{
        if(this.state.cat_id === null){
            getFeedContainers(this.context.product_id, this.state.feed_id).then((res)=>{
                this.setState({
                    containers:res.data.filter(
                        (item, i)=>{ 
                            return (!item.category || this.state.categories.filter(cat=>item.category.includes(cat.id)).length === 0)
                        }
                    )
                });
            });
        }else{
            getFeedCatContainers(this.context.product_id, this.state.cat_id).then((res)=>{
                this.setState({
                    containers:res.data
                });
            });
        }
    }
    getFeed = async () => {
        if(this.state.feed_id !== null && this.state.feed_id !== undefined)
        {
            await getFeed(this.context.product_id, this.state.feed_id).then((res)=>{
                this.setState({
                    feed:res.data
                });
            });
            return true
        }
        return false
    }
    getContainerAmount = () => {
        let amount = 0;
        for(let i = 0; this.state.containers.length > i; i++)
        {
            if(this.state.containers[i].feeds.includes(this.state.feed_id) && !this.state.containers[i].template){
                amount++;
            }
        }
        return amount;
    }
    getTemplateAmount = () =>{
        let amount = 0;
        for(let i = 0; this.state.containers.length > i; i++)
        {
            if((this.state.containers[i].feeds.includes(this.state.feed_id) || this.state.containers[i].feed_id === 'global' ) && this.state.containers[i].template){
                amount++;
            }
        }
        return amount;
    }
    switchTab = (view) =>{
        this.setState({
            view:view
        });
        if(this.state.cat_id){
            this.clearCategory()
        }
    }
    clearCategory = async () => {
        await this.setState({
            cat_id:null,
            containers:null,
            cat_name:null
        });
        this.update();
    }
    setCategory = async (cat_id, cat_name) => {
        await this.setState({
            cat_id:cat_id,
            containers:null,
            cat_name:cat_name,
            view:'containers'
        });
        this.update();
    }
    render(){
        return (this.state.containers)?(
            <Fragment>
                <PageTop 
                    name={(this.state.feed)?this.state.feed.name:'Loading'}
                    breadcrumbs={[
                        {
                            name:'Products',
                            uri:'/'
                        },{
                            name:this.context.product.name,
                            uri:'/product/'+this.context.product_id
                        },{
                            name:'Feeds',
                            uri:'/product/'+this.context.product_id+'/feeds/'
                        },{
                            name:(this.state.feed)?this.state.feed.name:'Loading',
                            uri:'/product/'+this.context.product_id+'/feeds/'+this.state.feed_id
                        }
                    ]}
                    buttons={<ContainerButtons 
                    update={this.update} 
                    feed_id={this.state.feed_id} templateAmount={this.getTemplateAmount()} containerAmount={this.getContainerAmount()} />} 
                />
                <div className='inner'>
                <div className="row containertabs">
                        <div className="col-12">
                            <ul className="nav nav-pills">
                                <li className="nav-item">
                                    <span className={'click nav-link '+((!this.state.cat_id && this.state.view === 'containers')&&'active')} onClick={()=>{this.switchTab('containers')}}>Containers</span>
                                </li>
                                <li className="nav-item">
                                    <span className={'click nav-link '+((!this.state.cat_id && this.state.view === 'templates')&&'active')} onClick={()=>{this.switchTab('templates')}}>Templates</span>
                                </li>
                                <li className="nav-item">
                                    <span className={'click nav-link '+((!this.state.cat_id && this.state.view === 'categories')&&'active')} onClick={()=>{this.switchTab('categories')}}>Categories</span>
                                </li>
                                {(this.state.cat_id !== null)&&<li className="nav-item">
                                    <span className={'click nav-link '+((this.state.cat_id && this.state.cat_name)&&'active')}>{this.state.cat_name}</span>
                                </li>}
                            </ul>
                        </div>
                    </div>
                    {(this.state.view === 'containers')&&
                        <Fragment>
                            
                            <div className="row">
                                <div className="col-12">
                                    {(this.getContainerAmount() > 0)?(
                                        <ul className="row mb-3 card-list">
                                            {this.state.containers.map(
                                                (item, i) => {
                                                    return (item.feeds.includes(this.state.feed_id) && !item.template)?<ContainerCard feed_id={this.state.feed_id} key={i} data={item} update={this.update} />:null
                                                }
                                            )}
                                        </ul>
                                    ):<NoContent />}
                                </div>
                            </div>
                        </Fragment>
                    }
                    {(this.state.view === 'templates')&&
                        <div className="row">
                            <div className="col-12">
                                {(this.getTemplateAmount() > 0)?(
                                    <ul className="row mb-3 card-list">
                                        {this.state.containers.map(
                                            (item, i) => {
                                                return ((item.feed_id === this.state.feed_id || item.feed_id === 'global') && item.template)?<ContainerCard template={true} key={i} data={item} update={this.update} />:null
                                            }
                                        )}
                                    </ul>
                                ):<NoContent />}
                            </div>
                        </div>
                    }
                    {(this.state.view === 'categories')&&<Categories setCategory={this.setCategory} feed_id={this.state.feed_id}/>}
                </div>
            </Fragment>
        ):(
            <Fragment>
                <PageTop name={'Loading Containers'} />
                <div className='inner'>
                    <Loading />
                </div>
            </Fragment>
        );
    }
}
FeedContainers.contextType = Store; // This part is important to access context values
export default FeedContainers;
