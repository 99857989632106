import React, {Fragment} from 'react';
//componants
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faPlus
} from '@fortawesome/free-solid-svg-icons';
import ModalAlert from '../Global/ModalAlert';
const Test = () => {
    return (
        <span>test</span>
    )
}
const DashboardButtons = (props) => {
    return (
        <Fragment>
            <ModalAlert 
                buttonClassName='boxbutton floatright' 
                buttonType='none'
                buttonIcon={<Fragment><FontAwesomeIcon icon={faPlus} /> <span>Container</span></Fragment>} 
                title={'New Page'} 
                content={<Test />}
                size='lg'
            />
            <ModalAlert 
                buttonClassName='boxbutton floatright' 
                buttonType='none'
                buttonIcon={<Fragment><FontAwesomeIcon icon={faPlus} /> <span>Feed</span></Fragment>} 
                title={'New Feed'} 
                content={<Test />}
                size='lg'
            />
        </Fragment>
    );
}
export default DashboardButtons;
