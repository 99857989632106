import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faTh
} from '@fortawesome/free-solid-svg-icons';
const NoContent = () => {
    return (
    <div className='row'>
        <div className='col-12 no_stuff'>
            <h1 className='largeIcon'><FontAwesomeIcon icon={faTh} /></h1>
                    <h1>No Content</h1>
                    <p>There is no Content to be displayed.</p>
        </div>
    </div>
    )
}
export default NoContent;