import React, {Component, Fragment} from 'react';
import {Store} from '../../stores/Store';
import {createFeedCategory, getFeedCategories} from '../../data/feeds';
import {getContainers} from '../../data/containers';
import Loading from '../Global/Loading';
class NewCategory extends Component {
    state = {
        form:{
            name:'',
            view:null,
            category:null
        },
        feed_id:this.props.feed_id,
        views:null
    }
    componentDidMount(){
        this.update();
    }
    handleChange = (e) => {
        let form = {...this.state.form}
        form[e.target.name] = e.target.value;
        this.setState({
          form:form
        });
    }
    update = () => {
        getContainers(this.context.product_id).then((res)=>{
            let views = [];
            for(let i = 0; res.data.length > i; i++)
            {
                if(res.data[i].feed_id === null && !res.data[i].template){
                    views.push(res.data[i]);
                }
            }
            this.setState(
            {
                views:views
            });
        });
        getFeedCategories(this.context.product_id, this.props.feed_id).then((res)=>{
            this.setState({
                categories:res.data
            });
        });
    }
    create = async() =>{
        let noerror = false;
        noerror = (this.state.form.name.length > 3 && noerror === false)?true:(noerror !== false)?noerror:'Name needs to be over 3 charactors';
        noerror = (this.state.form.name.length < 24 && noerror === false)?true:(noerror !== false)?noerror:'Name needs to be less than 24 charactors';
        if(noerror)
        {
            createFeedCategory(this.context.product_id, this.state.feed_id, this.state.form).then(()=>{
                this.props.update();
                this.props.close();
            });
        }
    }
    render(){
        return (this.state.views)?(
            <Fragment>
                <div className="input-group mb-3">
                    <div className="input-group-prepend">
                        <span className="input-group-text" id="namelabel">Category Name</span>
                    </div>
                    <input type="text" name='name' required={true} onChange={this.handleChange} value={this.state.form.name} className={(this.state.error && this.state.form.name.length < 3)?"form-control formerror":"form-control"} placeholder="Feed Name" />
                </div>
                <div className="input-group mb-3">
                    <div className="input-group-prepend">
                        <span className="input-group-text" id="namelabel">Sub Category</span>
                    </div>
                    <select name='category' required={true} onChange={this.handleChange} value={this.state.form.sub} className={"form-control"}>
                    <option value={''}>No Category</option>
                        {(this.state.categories)&&this.state.categories.map((item, i)=>{
                            return (!item.sub_cat || item.sub_cat ===null)?<option key={i} value={item.id}>{item.name}</option>:null;
                        })}
                    </select>
                </div>
                <div className="input-group mb-3">
                    <div className="input-group-prepend">
                        <span className="input-group-text" id="namelabel">Linked View</span>
                    </div>
                    <select name='view' required={true} onChange={this.handleChange} value={this.state.form.view} className={"form-control"}>
                    <option value={''}>No View</option>
                        {(this.state.views)&&this.state.views.map((item, i)=>{
                            return (
                                <option key={i} value={item.id}>{item.name}</option>
                            )
                        })}
                    </select>
                </div>
                <button className="btn btn-success nomargin floatright" onClick={this.create}>Create</button>
            </Fragment>
        ):<Loading />
    }
}
NewCategory.contextType = Store; // This part is important to access context values
export default NewCategory;