import React, {Fragment, Component} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faTrash, faEye, faPowerOff, faHandshake, faEdit
} from '@fortawesome/free-solid-svg-icons';
import {NavLink} from 'react-router-dom';
import {toggleActive, deleteProduct } from "../../../data/products";
import Processing from '../../Global/Processing';
import Share from '../Share';
import ModalAlert from '../../Global/ModalAlert';
import {Store} from "../../../stores/Store";
import EditProduct from '../EditProduct';
class ButtonBar extends Component{
    state = {
        processing:false
    }
    toggleActive = async () => {
        await this.setState({
            processing:true
        })
        await toggleActive(this.props.data.id, this.props.data.active); 
        await this.props.update(); 
        await this.setState({
            processing:false
        })
    }
    deleteProduct = async() => {
        await this.setState({
            processing:true
        })
        await deleteProduct(this.props.data.id);
        await this.setState({
            processing:false
        })
        await this.props.update(); 
    }
    render(){
        return (!this.state.processing)?(
            <div className="card-footer d-flex bg-gradient">
                <Fragment>
                    <div>
                    {(this.props.data.owner === this.context.uid)&&(
                        <Fragment>
                            <ModalAlert 
                                buttonClassName='btn btn-sm barbutton floatright' 
                                buttonType='none'
                                buttonIcon={<Fragment><FontAwesomeIcon icon={faEdit} /></Fragment>} 
                                title={'Edit'} 
                                content={<EditProduct data={this.props.data} update={this.props.update} />}
                                size='lg'
                            />
                            <ModalAlert 
                                buttonClassName='btn btn-sm barbutton floatright' 
                                buttonType='none'
                                buttonIcon={<Fragment><FontAwesomeIcon icon={faHandshake} /></Fragment>} 
                                title={'Share'} 
                                content={<Share product_id={this.props.data.id} update={this.props.update} />}
                                size='lg'
                            />
                        </Fragment>
                    )}
                        <NavLink className="btn btn-sm barbutton floatright" to={"/product/"+this.props.data.id}>
                            <FontAwesomeIcon icon={faEye} />
                        </NavLink>
                    </div>
                    {(this.props.data.owner === this.context.uid)&&(<div>
                        <button onClick={this.deleteProduct} className="btn btn-sm barbutton floatright warning"><FontAwesomeIcon icon={faTrash} /></button>
                        <button onClick={this.toggleActive}  className="btn btn-sm barbutton floatright warning"><FontAwesomeIcon icon={faPowerOff} /></button>
                    </div>)}
                </Fragment>
            </div>        
        ):<Processing />
    }  
}
ButtonBar.contextType = Store; // This part is important to access context values
export default ButtonBar;