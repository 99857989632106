import React, {Fragment} from 'react';
//componants
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faPlus
} from '@fortawesome/free-solid-svg-icons';
import ModalAlert from '../Global/ModalAlert';
import NewContainer from './NewContainer';
import NewContainerBucket from './NewContainerBucket';
const ContainerButtons = (props) => {
    return (
        <Fragment>
            <ModalAlert 
                buttonClassName='topboxbutton floatright' 
                buttonType='none'
                buttonIcon={<Fragment><FontAwesomeIcon icon={faPlus} /> <span>Bucket</span></Fragment>} 
                title={'New Bucket'} 
                content={<NewContainerBucket update={props.update} type={props.type} />}
                size='lg'
            />
            <ModalAlert 
                buttonClassName='topboxbutton floatright' 
                buttonType='none'
                buttonIcon={<Fragment><FontAwesomeIcon icon={faPlus} /> <span>Template</span></Fragment>} 
                title={'New Template'} 
                content={<NewContainer template={true} update={props.update} type={props.type} feed_id={props.feed_id} />}
                size='lg'
            />
            {(!props.feed_id || props.templateAmount > 0)&&<ModalAlert 
                buttonClassName='topboxbutton floatright' 
                buttonType='none'
                buttonIcon={<Fragment><FontAwesomeIcon icon={faPlus} /> <span>Container</span></Fragment>} 
                title={'New Container'} 
                content={<NewContainer update={props.update} feed_id={props.feed_id} type={props.type} />}
                size='lg'
            />}
        </Fragment>
    );
}
export default ContainerButtons;
