import React from 'react';

const Text = (props) =>{
    return (
        <div className="input-group mb-3">
            <div className="input-group-prepend">
                <div className="input-group-text">{props.item.name}</div>
            </div>
            <input 
                className="form-control" 
                name={props.item.id} 
                onChange={(e)=>{props.handleChange(e, 'text')}} 
                value={(props.currentValue !== null && props.currentValue !== undefined)?props.currentValue:props.item.value}
                placeholder={(props.item.placeholder)?props.item.placeholder:null} 
            />
        </div>
    )
}
export default Text;