import React, {Component} from 'react';
import {RegisterUser} from "../../data/users";
import {Store} from "../../stores/Store";
import {NavLink} from 'react-router-dom';
class Register extends Component {
    state = {
        form:[],
        failed:false,
        error:null,
        f_nameError:false,
        l_nameError:false,
        emailError:false,
        passwordError:false
    }
    passwordRules = () => {
        if(this.state.form.password.length > 6){
            return (this.state.form.password === this.state.form.checkpassword)?true:false
        }else{
            return false;
        }
    }
    register = async (event) =>{
        event.preventDefault();
        await this.setState(
            {
                f_nameError:(this.state.form.f_name.length > 0)?false:true,
                l_nameError:(this.state.form.l_name.length > 0)?false:true,
                emailError:(this.state.form.email.length > 3 && this.state.form.email.includes('@'))?false:true,
                passwordError:(this.passwordRules())?false:true
            }
        );
        if(!this.state.emailError && !this.state.f_nameError && !this.state.l_nameError && !this.state.passwordError)
        {
            RegisterUser(this.state.form).then((res)=>{
                if(res.success === true)
                {
                    this.props.history.push('/verify');
                }else{
                    this.setState(
                        {
                            failed:true,
                            error:res.error
                        }
                    );
                }
            });
        }
    }
    handleChange = (e) => {
        let form = {...this.state.form}
        form[e.target.name] = e.target.value;
        this.setState({
          form:form,
          passworderror:false
        });
      }
    render(){
        return (
            <section className="space-sm">
                <div className="container align-self-start ">
                    <div className="row justify-content-center">
                        <div className="col-12 col-md-8 col-lg-7">
                            <div className={"card card-lg text-center "+this.state.registerclass}>
                                <div className="card-body">
                                    <div className="mb-5">
                                        <div>
                                            <img alt='logo' style={{width:'30%'}} src={process.env.REACT_APP_LOGO} />
                                        </div>
                                        <br/>
                                        <p>Welcome please register your account here</p>
                                        <p><NavLink to="/">Back to sign in</NavLink></p>
                                    </div>
                                    {(this.state.failed === true)&&(
                                            <div className='col-12'>
                                                <div className="alert alert-warning" role="alert">
                                                    There has been an unknown error in your registration check your details or try again later!
                                                </div>
                                            </div>
                                        )}
                                    <div className="row justify-content-center">
                                        <div className='col-12'>
                                            <div className="form-group">
                                                <label>First Name</label>
                                                <input type="text" name='f_name' onChange={this.handleChange} className={(this.state.f_nameError)?"form-control form-control-lg formerror":"form-control form-control-lg"}  required />
                                            </div>
                                        </div>
                                        <div className='col-12'>
                                            <div className="form-group">
                                                <label>Second Name</label>
                                                <input type="text" name='l_name' onChange={this.handleChange} className={(this.state.l_nameError)?"form-control form-control-lg formerror":"form-control form-control-lg"}  required />
                                            </div>
                                        </div>
                                        <div className='col-12'>
                                            <div className="form-group">
                                                <label>Email Address</label>
                                                <input type="text" name='email' onChange={this.handleChange} className={(this.state.emailError)?"form-control form-control-lg formerror":"form-control form-control-lg"}  required />
                                            </div>
                                        </div>
                                        <div className='col-12'>
                                            <div className="form-group">
                                                <label>Password</label>
                                                <input type="password" name='password' className={(this.state.passwordError)?"form-control form-control-lg formerror":"form-control form-control-lg"} onChange={this.handleChange} required />
                                            </div>
                                        </div>
                                        <div className='col-12'>
                                            <div className="form-group">
                                                <label>Repeat Password</label>
                                                <input type="password" name='checkpassword' className={(this.state.passwordError)?"form-control form-control-lg formerror":"form-control form-control-lg"} onChange={this.handleChange} required />
                                            </div>
                                        </div>
                                        {(this.state.failed === true)&&(
                                            <div className='col-12'>
                                                <div className="alert alert-warning" role="alert">
                                                    {(this.state.error !== null)?this.state.error:"There has been an unknown error in your registration check your details or try again later!"}
                                                </div>
                                            </div>
                                        )}
                                        <div className="text-center mt-3">
                                            <button type="submit" className="btn btn-lg btn-primary" onClick={this.register}>Register</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}
Register.contextType = Store; // This part is important to access context values
export default Register;
