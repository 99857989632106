import  {db} from '../firebase';
export const getFolders = async (product_id) => {
    let responce = {
        success:true,
        data:[]
    }
    await db.collection('products').doc(product_id).collection('folders').get().then(snapshot => {
        let folders = [];
        if(!snapshot.empty)
        {
            snapshot.forEach(doc => {
                let folder =doc.data();
                folder.id = doc.id;
                folders.push(folder)
            });
            responce.data = folders;
        }
        return
    }).catch((error)=>{
        responce.success = false;
        responce.error = error;
        delete responce.data;
        return 
    });
    return responce;
}
export const getFolder = async (product_id, folder_id) => {
    let responce = {
        success:true,
        data:[]
    }
    await db.collection('products').doc(product_id).collection('folders').doc(folder_id).get().then(doc => {
        let data = doc.data();
        responce.data = data    
        return
    }).catch((error)=>{
        responce.success = false;
        responce.error = error;
        delete responce.data;
        return 
    });
    return responce;
}
export const createFolder = async (product_id, form) => {
    let responce = {
        success:true,
        data:[]
    }
    await db.collection('products').doc(product_id).collection('folders').add(
        {
            name:form.name,
            created:new Date().getTime(),
        }
    ).then(ref => {
        responce.data = ref
    }).catch((error)=>{
        responce.success = false;
        responce.error = error;
        delete responce.data;
        return 
    });
    return responce;
}
export const updateFolder = async (product_id, folder_id, form) => {
    let responce = {
        success:true,
        data:[]
    }
    await db.collection('products').doc(product_id).collection('folders').doc(folder_id).update(
        {
            name:form.name
        }
    ).then(ref => {
        responce.data = ref
    }).catch((error)=>{
        responce.success = false;
        responce.error = error;
        delete responce.data;
        return 
    });
    return responce;
}
export const deleteFolder = async (product_id, folder_id) => {
    let responce = {
        success:true
    }
    await db.collection('products').doc(product_id).collection('folder').doc(folder_id).delete().catch((error)=>{
        responce.success = false;
        responce.error = error;
        return 
    });
    return responce;
}