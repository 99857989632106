import React from 'react';
import ReactDOM from 'react-dom';
import App from "./App";

//// extent the prototype here if needed
String.prototype.capfirst = function() {
    return this.charAt(0).toUpperCase() + this.slice(1);
}

ReactDOM.render(<App />, document.getElementById('root'));
